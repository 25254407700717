import {useMemo} from 'react';

import {useLocation, useSearchParams} from 'react-router-dom';

/**
 * Gets the current location query parameters
 * @function
 * @returns {Object<string, string>}
 */
function useQueryString() {
    const {search} = useLocation();
    const q = useMemo(() => new URLSearchParams(search), [search]);
    return Object.fromEntries(q.entries());
}

export default useQueryString;
