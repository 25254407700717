export default ({
    public: {
        element: 'Public',
        type: 'page',
        props: {},
        children: {
            login: {
                path: '/login',
                element: 'Login/LoginContainer',
                type: 'page',
                props: {}
            },
            forgotPassword: {
                path: '/forgot-password',
                element: 'ForgotPassword/ForgotPasswordContainer',
                type: 'page',
                props: {}
            },
            resetPassword: {
                path: '/reset-password/:code',
                element: 'ResetPassword/ResetPasswordContainer',
                type: 'page',
                props: {}
            },
            confirmAccount: {
                path: '/confirm-account/:code',
                element: 'Activation/ActivationContainer',
                type: 'page',
                props: {
                    activation_type: 'activate-account',
                    activation_name: 'account-activation'
                }
            },
            confirmEmail: {
                path: '/confirm-email/:code',
                element: 'Activation/ActivationContainer',
                type: 'page',
                props: {
                    activation_type: 'confirm-email',
                    activation_name: 'email-confirmation'
                }
            },
            reactivateAccount: {
                path: '/reactivate/:code',
                element: 'Activation/ActivationContainer',
                type: 'page',
                props: {
                    activation_type: 'reactivate-account',
                    activation_name: 'account-reactivation'
                }
            },
            requestNewCode: {
                path: '/request-new-code/:activation_type',
                element: 'Activation/RequestNewCodeContainer',
                type: 'page',
                props: {}
            },
            changePassword: {
                path: '/change-password',
                element: 'ChangePassword/ChangePasswordContainer',
                type: 'page',
                props: {}
            },
            tos: {
                path: '/terms/:language',
                element: 'TermsOfService/TermsOfService',
                type: 'page',
                props: {}
            },
            privacy: {
                path: '/privacy/:language',
                element: 'PrivacyPolicy/PrivacyPolicy',
                type: 'page',
                props: {}
            }
        }
    },
    private: {
        path: '/',
        element: 'Authenticated',
        type: 'page',
        props: {},
        children: {
            logout: {
                index: true,
                path: 'logout',
                element: 'LogoutContainer',
                type: 'page',
                props: {}
            },
            changePassword: {
                index: true,
                path: 'change-password',
                element: 'ChangePassword/ChangePasswordContainer',
                type: 'page',
                props: {}
            },
            validateMFA: {
                index: true,
                path: 'mfa/validate',
                element: 'MFAValidate/MFAValidateContainer',
                type: 'page',
                props: {}
            },
            root: {
                element: 'Layout',
                type: 'page',
                path: '',
                children: {
                    home: {
                        index: true,
                        type: 'page',
                        element: 'Home/HomeContainer',
                        props: {}
                    },
                    admin: {
                        type: 'page',
                        path: 'admin',
                        scopes: ['isInternal'],
                        children: {
                            dashboard: {
                                index: true,
                                type: 'page',
                                element: 'Admin/Dashboard/DashboardContainer',
                                props: {}
                            },
                            test_results: {
                                type: 'page',
                                path: 'test-results',
                                scopes: ['list-test-results'],
                                element: 'Admin/TestResults/ListContainer',
                                props: {}
                            }
                        }
                    },
                    roles: {
                        path: 'roles',
                        element: 'Roles/RolesContainer',
                        type: 'page',
                        children: {
                            list: {
                                path: '',
                                index: true,
                                element: 'Roles/List/ListContainer',
                                props: {},
                                type: 'page',
                                scopes: ['list-roles']
                            },
                            one: {
                                path: ':roleId',
                                type: 'page',
                                children: {
                                    details: {
                                        path: '',
                                        type: 'page',
                                        index: true,
                                        props: {},
                                        scopes: ['view-role'],
                                        element: 'Roles/Detail/DetailContainer'
                                    },
                                    edit: {
                                        path: 'edit',
                                        element: 'Roles/Edit/EditContainer',
                                        type: 'page',
                                        props: {},
                                        scopes: ['edit-role']
                                    }
                                }
                            }
                        }
                    },
                    permissions: {
                        path: 'permissions',
                        element: 'Permissions/PermissionsContainer',
                        type: 'page',
                        children: {
                            list: {
                                path: '',
                                index: true,
                                element: 'Permissions/List/ListContainer',
                                props: {},
                                type: 'page',
                                scopes: ['list-permissions']
                            },
                            one: {
                                path: ':permissionId',
                                type: 'page',
                                children: {
                                    details: {
                                        path: '',
                                        index: true,
                                        props: {},
                                        type: 'page',
                                        scopes: ['view-permission'],
                                        element: 'Permissions/Detail/DetailContainer'
                                    },
                                    edit: {
                                        path: 'edit',
                                        element: 'Permissions/Edit/EditContainer',
                                        type: 'page',
                                        props: {},
                                        scopes: []
                                    }
                                }
                            }
                        }
                    },
                    modules: {
                        path: 'modules',
                        element: 'Modules/ModulesContainer',
                        type: 'page',
                        children: {
                            list: {
                                path: '',
                                index: true,
                                element: 'Modules/List/ListContainer',
                                props: {},
                                type: 'page',
                                scopes: ['list-modules']
                            },
                            one: {
                                path: ':moduleId',
                                type: 'page',
                                children: {
                                    details: {
                                        path: '',
                                        index: true,
                                        props: {},
                                        type: 'page',
                                        scopes: ['view-module'],
                                        element: 'Modules/Detail/DetailContainer'
                                    },
                                    edit: {
                                        path: 'edit',
                                        element: 'Modules/Edit/EditContainer',
                                        type: 'page',
                                        props: {},
                                        scopes: []
                                    }
                                }
                            }
                        }
                    },
                    users: {
                        path: 'users',
                        element: 'Users/UsersContainer',
                        type: 'page',
                        children: {
                            list: {
                                path: '',
                                index: true,
                                element: 'Users/List/ListContainer',
                                props: {},
                                type: 'page',
                                scopes: ['list-users']
                            },
                            one: {
                                path: ':userId/:tab?',
                                type: 'page',
                                element: 'Users/One/UserContainer',
                                children: {
                                    details: {
                                        index: true,
                                        props: {},
                                        type: 'page',
                                        scopes: ['view-user'],
                                        element: 'Users/One/DetailContainer'
                                    },
                                    changePassword: {
                                        path: 'change-password',
                                        element: 'ChangePassword/ChangePasswordContainer',
                                        type: 'page',
                                        props: {},
                                        scopes: []
                                    }
                                }
                            }
                        }
                    },
                    patients: {
                        path: 'participants',
                        element: 'Patients/PatientsContainer',
                        type: 'page',
                        children: {
                            list: {
                                path: '',
                                index: true,
                                element: 'Patients/List/ListContainer',
                                props: {},
                                type: 'page',
                                scopes: ['list-patients']
                            },
                            one: {
                                path: ':patientId',
                                type: 'page',
                                element: 'Patients/One/PatientContainer',
                                children: {
                                    overview: {
                                        type: 'page',
                                        index: true,
                                        props: {},
                                        scopes: ['view-patient'],
                                        element: 'Patients/One/Summary/SummaryContainer'
                                    },
                                    personal: {
                                        type: 'page',
                                        path: 'personal/:tab?',
                                        scopes: ['view-patient'],
                                        element: 'Patients/One/Personal/PersonalContainer'
                                    },
                                    health_profile: {
                                        type: 'page',
                                        path: 'health-profile',
                                        props: {},
                                        scopes: ['list-patient-medical-histories', 'list-patient-allergies', 'list-patient-medications', 'list-patient-cognitive-statuses'],
                                        element: 'Patients/One/HealthProfile/HealthProfileContainer'
                                    },
                                    documents: {
                                        type: 'page',
                                        path: 'documents',
                                        props: {},
                                        scopes: ['list-patient-documents'],
                                        element: 'Patients/One/Documents/List/DocumentsContainer'
                                    },
                                    assessments: {
                                        type: 'page',
                                        path: 'assessments',
                                        element: 'Patients/One/Assessments/AssessmentsContainer',
                                        children: {
                                            list: {
                                                path: '',
                                                index: true,
                                                props: {},
                                                type: 'page',
                                                element: 'Patients/One/Assessments/List/ListContainer',
                                                scopes: ['list-patient-questionnaires']
                                            },
                                            one: {
                                                path: ':patientAssessmentId',
                                                type: 'page',
                                                element: 'Patients/One/Assessments/One/AssessmentContainer',
                                                children: {
                                                    view: {
                                                        path: '',
                                                        type: 'page',
                                                        index: true,
                                                        element: 'Patients/One/Assessments/One/ViewContainer',
                                                        props: {},
                                                        scopes: ['view-patient-questionnaire']
                                                    }
                                                }
                                            }
                                        }
                                    },
                                    imaging: {
                                        type: 'page',
                                        path: 'imaging',
                                        props: {},
                                        element: 'Patients/One/Imaging/ImagingContainer',
                                        children: {
                                            list: {
                                                path: '',
                                                index: true,
                                                props: {},
                                                type: 'page',
                                                element: 'Patients/One/Imaging/List/ListContainer',
                                                scopes: ['list-imaging-results']
                                            },
                                            one: {
                                                path: ':imagingId',
                                                props: {},
                                                type: 'page',
                                                element: 'Patients/One/Imaging/One/ImageContainer',
                                                children: {
                                                    view: {
                                                        path: '',
                                                        index: true,
                                                        props: {},
                                                        type: 'page',
                                                        scopes: ['view-imaging-result'],
                                                        element: 'Patients/One/Imaging/One/Details/DetailContainer'
                                                    }
                                                }
                                            }
                                        }
                                    },
                                    test_results: {
                                        type: 'page',
                                        path: 'test-results',
                                        props: {},
                                        element: 'Patients/One/TestResults/TestResultsContainer',
                                        children: {
                                            list: {
                                                path: '',
                                                index: true,
                                                props: {},
                                                type: 'page',
                                                element: 'Patients/One/TestResults/List/ListContainer',
                                                scopes: ['list-test-results']
                                            },
                                            one: {
                                                path: ':testResultId',
                                                props: {},
                                                type: 'page',
                                                scopes: ['view-test-result'],
                                                element: 'Patients/One/LabResults/One/View/TestResultContainer'
                                            }
                                        }
                                    },
                                    lab_results: {
                                        type: 'page',
                                        path: 'lab-results',
                                        props: {},
                                        element: 'Patients/One/LabResults/LabResultsContainer',
                                        children: {
                                            list: {
                                                path: '',
                                                index: true,
                                                props: {},
                                                type: 'page',
                                                element: 'Patients/One/LabResults/List/ListContainer',
                                                scopes: ['list-lab-results']
                                            },
                                            one: {
                                                path: ':labResultId',
                                                props: {},
                                                type: 'page',
                                                element: 'Patients/One/LabResults/One/LabResultContainer',
                                                children: {
                                                    view: {
                                                        path: '',
                                                        index: true,
                                                        props: {},
                                                        type: 'page',
                                                        scopes: ['view-lab-result'],
                                                        element: 'Patients/One/LabResults/One/Details/DetailsContainer'
                                                    }
                                                }
                                            }
                                        }
                                    },
                                    vitals: {
                                        type: 'page',
                                        path: 'vitals',
                                        props: {},
                                        scopes: ['list-vitals'],
                                        element: 'Patients/One/Vitals/VitalsContainer'
                                    },
                                    visits: {
                                        type: 'page',
                                        path: 'visits',
                                        props: {},
                                        element: 'Patients/One/Visits/VisitsContainer',
                                        children: {
                                            list: {
                                                path: '',
                                                index: true,
                                                props: {},
                                                type: 'page',
                                                element: 'Patients/One/Visits/List/ListContainer',
                                                scopes: ['list-visits']
                                            },
                                            one: {}
                                        }
                                    }
                                }
                            }
                        }
                    },
                    organizations: {
                        path: 'organizations',
                        element: 'Organizations/OrganizationsContainer',
                        type: 'page',
                        children: {
                            list: {
                                path: '',
                                index: true,
                                element: 'Organizations/List/ListContainer',
                                props: {},
                                type: 'page',
                                scopes: ['list-organizations']
                            },
                            create: {
                                path: 'new',
                                element: 'Organizations/Form/FormContainer',
                                type: 'page',
                                props: {},
                                scopes: ['create-organization']
                            },
                            one: {
                                path: ':organizationId',
                                type: 'page',
                                element: 'Organizations/OrganizationContainer',
                                children: {
                                    details: {
                                        type: 'page',
                                        index: true,
                                        props: {},
                                        scopes: ['view-organization'],
                                        element: 'Organizations/One/Summary/SummaryContainer'
                                    },
                                    edit: {
                                        path: 'edit',
                                        element: 'Organizations/Form/FormContainer',
                                        type: 'page',
                                        props: {},
                                        scopes: ['update-organization']
                                    },
                                    users: {
                                        path: 'users',
                                        element: 'Users/UsersContainer',
                                        type: 'page',
                                        children: {
                                            list: {
                                                path: '',
                                                index: true,
                                                props: {},
                                                type: 'page',
                                                element: 'Users/List/ListContainer',
                                                scopes: ['list-users']
                                            },
                                            one: {
                                                path: ':userId',
                                                element: 'Users/Detail/DetailContainer',
                                                props: {},
                                                type: 'page',
                                                scopes: ['view-user']
                                            }
                                        }
                                    },
                                    assessments: {
                                        path: 'assessments',
                                        element: 'Organizations/One/Assessments/AssessmentsContainer',
                                        type: 'page',
                                        children: {
                                            list: {
                                                path: '',
                                                index: true,
                                                props: {},
                                                type: 'page',
                                                element: 'Organizations/One/Assessments/List/ListContainer',
                                                scopes: ['list-assign-assessments']
                                            },
                                            edit: {}
                                        }
                                    },
                                    locations: {
                                        path: 'locations',
                                        type: 'page',
                                        element: 'Organizations/One/Locations/LocationsContainer',
                                        children: {
                                            list: {
                                                path: '',
                                                index: true,
                                                element: 'Organizations/One/Locations/List/ListContainer',
                                                props: {},
                                                type: 'page',
                                                scopes: ['list-locations']
                                            },
                                            one: {
                                                path: ':locationId',
                                                element: 'Organizations/One/Locations/One/LocationContainer',
                                                props: {},
                                                type: 'page',
                                                scopes: ['view-location']
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
});
