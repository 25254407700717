import {ScopeContext} from 'common/ScopeContext';
import {useCallback} from 'react';
import * as React from 'react';

/**
 * Gets the scopes of the current user. Used to determine whether the user has the necessary access to various parts of
 * the application.
 * @returns {Object<{
 *  can: (function(string): boolean),
 *  canNot: (function(string): boolean),
 *  isAdministrator: boolean,
 *  isInternal: boolean,
 *  isPatient: boolean,
 *  state: string[],
 *  dispatch: func
 * }>}
 */
function useScopes() {
    const context = React.useContext(ScopeContext);

    if (context === undefined) {
        throw new Error('useScopes should be used within a ScopeContextProvider');
    }
    const {state, dispatch} = context;

    const can = useCallback((scope) => {
        return state.includes(scope);
    }, [state]);

    const canNot = useCallback((scope) => {
        return !state.includes(scope);
    }, [state]);

    const isPatient = state.includes('isPatient');
    const isAdministrator = state.includes('isAdministrator');
    const isInternal = state.includes('isInternal');

    return {can, canNot, isPatient, isAdministrator, isInternal, state, dispatch};
}

export default useScopes;
