import {parse} from 'path-to-regexp';

const idRegex = /^\w{8}-\w{4}-\w{4}-\w{4}-\w{12}$/g;
/**
 * Parses params from location data
 * @param {string} pathname - location pathname
 * @param {string} linkPattern - route pattern (Example: /organizations/:organizationId)
 * @returns {Object} - params
 * @function
 */
export const parseParamsFromLocation = (pathname, linkPattern) => {
    const parsedLink = parse(linkPattern);
    const [, ...splitPathname] = pathname.split('/');

    return splitPathname.reduce((acc, item, index) => {
        if (typeof parsedLink[index] === 'string') {
            return acc;
        }

        if (parsedLink[index] && item.match(idRegex)) {
            acc[parsedLink[index].name] = item;
        }

        return acc;
    }, {});
};
