import {createSlice, nanoid} from '@reduxjs/toolkit';

const initialState = {
    sidebars: {
        main: {
            show: true
        },
        secondary: {
            show: false
        },
        right: {
            show: false
        }
    },
    notifications: {},
    wizard: {
        currentStep: 0,
        data: null
    },
    alert: null,
    loading: [],
    theme: 'light',
    dialog: null
};

const UISlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        showSidebar: (state, action) => {
            const {location, ...rest} = action.payload;
            state.sidebars[location] = {
                show: true,
                ...rest
            };
        },
        hideSidebar: (state, action) => {
            const {location, ...rest} = action.payload;
            state.sidebars[location] = {
                show: false
            };
        },
        toggleSidebar: (state, action) => {
            const {location, ...rest} = action.payload;
            // In immer you can manipulate state, it handles internally
            state.sidebars[location].show = !state.sidebars[action.payload.location].show;
        },
        showSidebarForm: (state, action) => {
            const {component, ComponentProps, ...rest} = action.payload;
            state.sidebars.right = {
                show: true,
                component: () => component,
                ComponentProps,
                ...rest
            };
        },
        closeSidebarForm: (state, action) => {
            state.sidebars.right = {
                show: false
            };
        },
        errorNotification: {
            reducer: (state, action) => {
                const id = nanoid();
                state.notifications[id] = {...action.payload, index: id};
            },
            prepare: payload => {
                Object.assign(payload, {
                    severity: 'error',
                    timeout: 4000
                });
                return {payload};
            }
        },
        successNotification: {
            reducer: (state, action) => {
                const id = nanoid();
                state.notifications[id] = {...action.payload, index: id};
            },
            prepare: payload => {
                Object.assign(payload, {
                    severity: 'success',
                    timeout: 6000
                });
                return {payload};
            }
        },
        showNotification: (state, action) => {
            const id = nanoid();
            state.notifications[id] = {...action.payload, index: id};
        },
        closeNotification: (state, action) => {
            delete state.notifications[action.payload];
        },
        setWizard: (state, action) => {
            state.wizard = action.payload;
        },
        resetWizard: (state, action) => {
            state.wizard = initialState.wizard;
        },
        showAlert: (state, action) => {
            state.alert = action.payload;
        },
        closeAlert: (state, action) => {
            state.alert = initialState.alert;
        },
        loading: (state, action) => {
            if (typeof action.payload !== 'string') {
                state.loading.push(action.payload);
            }
            else {
                state.loading = state.loading.filter(item => item.type !== action.payload);
            }
        },
        showLazyDialog: (state, action) => {
            const {component, ComponentProps, ...rest} = action.payload;
            state.dialog = {
                open: true,
                component: () => component,
                ComponentProps,
                ...rest
            };
        },
        closeLazyDialog: (state, action) => {
            state.dialog = initialState.dialog;
        },
        toggleTheme: (state, action) => {
            state.theme = action.payload || 'main';
        }
    },
    extraReducers: {}
});
export const {
    showSidebar,
    hideSidebar,
    toggleSidebar,
    showSidebarForm,
    closeSidebarForm,
    showNotification,
    closeNotification,
    errorNotification,
    successNotification,
    setWizard,
    resetWizard,
    loading,
    showAlert,
    closeAlert,
    showLazyDialog,
    closeLazyDialog,
    toggleTheme
} = UISlice.actions;

export default UISlice;
