import {Alert} from '@mui/lab';
import {Slide, Snackbar} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {closeNotification} from 'store/ui/UI.slice';
import UISelector from 'store/ui/UI.selectors';
import {useSnackbar} from 'notistack';

function Notification(props) {

    const {
        notifications,
        timeout,
        position,
        closeNotification,
        translationNs = null
    } = props;

    const {t} = useTranslation(['common', translationNs].filter(Boolean));

    const [stack, setStack] = useState([]);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const handleExited = (index) => {
        closeNotification(index);
    };

    useEffect(() => {
        for (const notification of Object.keys(notifications)) {
            if (stack && !stack.includes(notification)) {
                const current = notifications[notification];
                enqueueSnackbar(t(current.text), {
                    variant: current.severity || 'info',
                    anchorOrigin: {
                        horizontal: 'center',
                        vertical: 'bottom'
                    },
                    onClose: e => handleExited(notification),
                    autoHideDuration: current?.timeout || timeout,
                    TransitionProps: {
                        onExited: e => handleExited(notification)
                    }
                });
                setStack([...stack, notification]);
            }
        }
    }, [notifications, stack]);
    return null;
}

Notification.propTypes = {
    timeout: PropTypes.number,
    position: PropTypes.shape({vertical: PropTypes.string, horizontal: PropTypes.string}),
    closeNotification: PropTypes.func,
    notifications: PropTypes.object,
    translationNs: PropTypes.string
};

Notification.defaultProps = {
    timeout: 5000,
    position: {
        vertical: 'bottom', horizontal: 'left'
    }
};

const mapStateToProps = (state) => {
    return {
        notifications: UISelector.getNotifications(state)
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        closeNotification: (index) => dispatch(closeNotification(index))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
