import store from 'store/index';
import {errorNotification} from 'store/ui/UI.slice';

/**
 * A throwable error that is used to show error message for the user
 * @class
 */
class SystemError {
    /**
     * @param {Object<{
     *     error: string,
     *     message: string
     * }>} props
     */
    constructor({...props}) {
        const {
            error,
            message: error_message,
            ...rest
        } = props;
        process.env.REACT_APP_ENV === 'local' && console.log(props);
        store.dispatch(errorNotification({text: error_message}));
    }
}

export default SystemError;
