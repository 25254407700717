import Config from 'common/Config';
import SessionStorage from 'common/SessionStorage';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import titleCase from 'lodash/startCase';
import moment from 'moment';
import 'moment/min/locales';
import {initReactI18next} from 'react-i18next';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import resourcesToBackend from 'i18next-resources-to-backend';

const apiPath = `${Config.get('locale.url', '/').replace(/\/$/, '')}/{{lng}}/{{ns}}`;

i18n
    .use(ChainedBackend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        initImmediate: false,

        backend: {
            backends: [
                resourcesToBackend((lng, ns) => import(`../locales/${lng}/${ns}.json`))
                // HttpBackend
            ],
            backendOptions: [{}, {
                loadPath: apiPath,
                allowMultiLoading: true,
                crossDomain: true,
                requestOptions: {
                    // cache: 'default'
                }
            }]
        },
        detection: {
            order: ['sessionStorage', 'navigator'],
            caches: ['sessionStorage'],
            excludeCacheFor: ['cimode']
        },
        // nonExplicitSupportedLngs: true,
        ns: ['common', 'messages', 'errors', 'login', 'patients'],
        lang: SessionStorage.get('i18nextLng') || 'en',
        lowerCaseLng: true,
        cleanCode: true,
        fallbackLng: 'en',
        load: 'all',
        debug: false,
        interpolation: {
            escapeValue: false, // react already safes from xss
            format: function (value, format, lng) {
                if (format === 'uppercase') {
                    return value.toUpperCase();
                }
                if (format === 'titlecase') {
                    return titleCase(value);
                }
                if (value instanceof Date) {
                    return moment(value).format(format);
                }
                return value;
            }
        }
    });
i18n.on('initialized', function (options) {
    // moment.locale(i18n.languages[0]);

});

i18n.on('languageChanged', function (lng) {
    moment.locale(lng);
});

export default i18n;
