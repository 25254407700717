import SessionStorage from 'common/SessionStorage';
import slice from 'lodash/slice';
import PropTypes from 'prop-types';
import * as React from 'react';

export const BreadcrumbContext = React.createContext(null);

const initialState = [{
    label: 'dashboard:home',
    route: 'private.root',
    index: 0
}];

/**
 * @typedef {Object<{
 *   index: number,
 *   label: string,
 *   route: string[]
 * }>} BreadcrumbItem
 */

/**
 * Breadcrumbs reducer used to store breadcrumbs data
 * @param {BreadcrumbItem[]} state
 * @param {Object<{
 *   type: 'set',
 *   index: number,
 *   label: string,
 *   route: string[]
 * }>} newData
 * @returns {BreadcrumbItem[]}
 */
function breadCrumbReducer(state = initialState, {index, type, ...data}) {
    let newState = [...state];
    switch (type) {
        case 'set': {
            if (typeof data.route === 'undefined' || !data.route) {
                newState = [...initialState];
            }
            else if (typeof index === 'undefined' || index === null || index === 0) {
                newState = [{...data}];
            }
            else {
                newState = [...slice(state, 0, index), {...data}];
            }
            return newState;
        }
        default: {
            throw new Error('Unhandled exception');
        }
    }
}

/**
 * Provides breadcrumb data down the JSX tree
 * @param {JSX.Element} children
 * @returns {JSX.Element}
 * @constructor
 */
function BreadcrumbProvider({children}) {
    const sessionStorage = SessionStorage.get('breadcrumbs');
    const [state, dispatch] = React.useReducer(breadCrumbReducer, sessionStorage ? JSON.parse(sessionStorage) : initialState);
    const value = {state, dispatch};

    return (
        <BreadcrumbContext.Provider value={value}>
            {children}
        </BreadcrumbContext.Provider>
    );
}

BreadcrumbProvider.propTypes = {
    children: PropTypes.node
};

export default BreadcrumbProvider;
