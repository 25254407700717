import withStyles from '@mui/styles/withStyles';
import * as PropTypes from 'prop-types';
import React from 'react';

const LogoSpinner = ({theme, classes, ...props}) => {
    return (
        <div className={classes.root}>
            <div className={classes.spinnerContainer}>
                <div className={classes.spinnerRotator}>
                    <div className={classes.spinnerLeft}>
                        <div className={classes.spinnerCircle} />
                    </div>
                    <div className={classes.spinnerRight}>
                        <div className={classes.spinnerCircle} />
                    </div>
                </div>
            </div>
        </div>
    );
};
LogoSpinner.defaultProps = {};

LogoSpinner.propTypes = {
    theme: PropTypes.object,
    classes: PropTypes.object
};

const styles = theme => ({
    root: {
        position: 'absolute',
        left: '50%',
        top: `calc(50% - ${theme.spacing(4)})`,
        width: theme.spacing(9),
        height: theme.spacing(9),
        marginLeft: theme.spacing(-4.5),
        zIndex: 18,
        pointerEvents: 'none'
    },
    spinnerContainer: {
        pointerEvents: 'none',
        position: 'absolute',
        width: '100%',
        paddingBottom: '100%',
        top: '50%',
        left: '50%',
        marginTop: '-50%',
        marginLeft: '-50%',
        '-webkit-animation': 'ytp-spinner-linspin 1568.23529647ms cubic-bezier(0.4, 0, 0.2, 1) infinite both',
        '-moz-animation': 'ytp-spinner-linspin 1568.23529647ms cubic-bezier(0.4, 0, 0.2, 1) infinite both',
        '-o-animation': 'ytp-spinner-linspin 1568.23529647ms cubic-bezier(0.4, 0, 0.2, 1) infinite both',
        animation: 'ytp-spinner-linspin 1568.23529647ms cubic-bezier(0.4, 0, 0.2, 1) infinite both'
    },
    spinnerRotator: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        '-webkit-animation': 'ytp-spinner-easespin 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both',
        '-moz-animation': 'ytp-spinner-easespin 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both',
        '-o-animation': 'ytp-spinner-easespin 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both',
        animation: 'ytp-spinner-easespin 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both'
    },
    spinnerLeft: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        overflow: 'hidden',
        right: '50%',
        '& > div': {
            left: 0,
            right: '-100%',
            borderRightColor: theme.palette.grey['100'],
            '-webkit-animation': 'ytp-left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both',
            '-moz-animation': 'ytp-left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both',
            '-o-animation': 'ytp-left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both',
            animation: 'ytp-left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both'
        }
    },
    spinnerRight: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        overflow: 'hidden',
        left: '50%',
        '& > div': {
            left: '-100%',
            right: 0,
            borderLeftColor: theme.palette.grey['100'],
            '-webkit-animation': 'ytp-right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both',
            '-moz-animation': 'ytp-right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both',
            '-o-animation': 'ytp-right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both',
            animation: 'ytp-right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both'
        }
    },
    spinnerCircle: {
        boxSizing: 'border-box',
        position: 'absolute',
        width: '200%',
        height: '100%',
        borderStyle: 'solid',
        borderColor: `${theme.palette.altoida.dawn.main} ${theme.palette.altoida.spring.main} ${theme.palette.altoida.blossom.main} ${theme.palette.altoida.dusk.main}`,
        borderRadius: '50%',
        borderWidth: theme.spacing(1)
    },
    logo: {
        margin: theme.spacing(1.5, .5)
    }
});
export default withStyles(styles, {withTheme: true})(LogoSpinner);
