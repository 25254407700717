import {createAsyncThunk} from '@reduxjs/toolkit';
import API from 'common/API';

class ConfigService {
    constructor(props) {
        this._getConfiguration = this._getConfiguration.bind(this);
        this.getConfiguration = createAsyncThunk('config/get', this._getConfiguration);
    }

    async _getConfiguration({params, additionalHeaders}) {
        const endpoint = API.resolveRoute('auth', 'config');
        const configResponse = await API.request(endpoint.url, null, endpoint.method, false, additionalHeaders);
        if (configResponse) {
            return configResponse;
        }
        throw configResponse;
    }
}

export default new ConfigService();
