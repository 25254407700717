/**
 * OrganizationService definitions
 */

import {createAsyncThunk} from '@reduxjs/toolkit';
import API from 'common/API';
import BaseService from 'store/BaseService';
import store from 'store/index';
import LocationService from 'store/location/Location.service';

class OrganizationService extends BaseService {

    constructor(props) {
        super({
            model: 'organization',
            service: 'organization',
            isPublic: false
        });
        this._addModule = this._addModule.bind(this);
        this._removeModule = this._removeModule.bind(this);
        this._updateCredit = this._updateCredit.bind(this);

        this.updateCredit = createAsyncThunk(`${this.model}/updateCredit`, this._updateCredit);
        this.addModule = createAsyncThunk(`${this.model}/addModule`, this._addModule);
        this.removeModule = createAsyncThunk(`${this.model}/removeModule`, this._removeModule);
    }

    _updateCredit = async ({data, props}) => {
        await this._validate();
        const {url, method} = API.resolveRoute(this.service, 'transferCredit', props);
        const result = await API.request(url, data, method, false);
        if (!result && !result.error) {
            throw result;
        }
        store.dispatch(this.getById(props.id));
    };

    _addModule = async ({moduleId, organizationId}, thunkApi) => {
        await this._validate();
        const {url, method} = API.resolveRoute(this.service, 'addModule', {organizationId});
        const result = await API.request(url, {moduleId}, method, false);
        if (!result || result.error) {
            throw result;
        }
        store.dispatch(this.getById(organizationId));
    };

    _removeModule = async ({moduleId, organizationId}, thunkApi) => {
        await this._validate();
        const {url, method} = API.resolveRoute(this.service, 'removeModule', {organizationId, moduleId});
        const result = await API.request(url, {}, method, false);
        if (!result || result.error) {
            throw result;
        }
        store.dispatch(this.getById(organizationId));
    };
}

export default new OrganizationService();
