import {createAsyncThunk} from '@reduxjs/toolkit';
import API from 'common/API';
import Config from 'common/Config';
import _ from 'lodash';
import camelCase from 'lodash/camelCase';
import extend from 'lodash/extend';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import startCase from 'lodash/startCase';
import {parse} from 'path-to-regexp';
import pluralize from 'pluralize';
import store from 'store/index';

class BaseService {

    model = '';
    service = '';
    isPublic = false;
    crud = {
        get: true,
        delete: true,
        create: true,
        update: true,
        list: true
    };

    constructor({crud, ...props}) {
        extend(this, props, {crud: {...this.crud, ...crud}});

        if (this.constructor.name === 'BaseService') {
            throw new TypeError('BaseService is an abstraction class. Can not create an instance');
        }

        this._list = this._list.bind(this);
        this._getById = this._getById.bind(this);
        this._delete = this._delete.bind(this);
        this._save = this._save.bind(this);
        this._validate = this._validate.bind(this);

        this.modelName = _.chain(this.model).split('.').last().value();
        this.slicePath = this.model.replace(/\./gi, '/');

        this.list = createAsyncThunk(`${this.slicePath}/list`, async (params, thunkAPI) => this._list(params, thunkAPI));
        this.getById = createAsyncThunk(`${this.slicePath}/getById`, async (id = null, thunkAPI) => this._getById(id, thunkAPI));
        this.save = createAsyncThunk(`${this.slicePath}/upsert`, async (data = null, thunkAPI) => this._save(data, thunkAPI));
        this.delete = createAsyncThunk(`${this.slicePath}/delete`, async (id = null, thunkAPI) => this._delete(id, thunkAPI));

    }

    async _validate() {
        // eslint-disable-next-line no-prototype-builtins
        if (typeof this.constructor.hasOwnProperty('service') === 'undefined' || !this.service || this.service === '') {
            throw new TypeError(`${this.constructor.name} is missing service definition`);
        }

        // eslint-disable-next-line no-prototype-builtins
        if (typeof this.constructor.hasOwnProperty('model') === 'undefined' || !this.model || this.model === '') {
            throw new TypeError(`${this.constructor.name} is missing model definition`);
        }

        if (typeof this.isPublic !== 'object') {
            this.isPublic = {
                get: this.isPublic,
                create: this.isPublic,
                list: this.isPublic,
                update: this.isPublic,
                delete: this.isPublic
            };
        }
        return this.initialize();
    }

    initialize() {
        if (!store.reducerManager.has(this.model)) {
            const reducer = import(`store/${this.slicePath}/${startCase(camelCase(this.modelName)).replace(/\s/gi, '')}.slice`);
            return reducer.then(r => {
                store.reducerManager.add(this.model, r.default.reducer);
            });
        }
        return Promise.resolve();
    }

    remove() {
        // if (store.reducerManager.has(this.model)) {
        store.reducerManager.remove(this.model);
        store.dispatch({type: '@@store/cleanup'});
        // }
    }

    async _getById(id = null, {rejectWithValue}) {
        await this._validate();
        try {
            const endpoint = API.resolveRoute(this.service, 'get', {[`${pluralize(this.modelName, 1)}Id`]: id});
            return await API.request(endpoint.url, {}, endpoint.method, get(this.isPublic, 'get', this.isPublic));
        }
        catch (e) {
            return rejectWithValue(e);
        }
    }

    async _list(params = {}, {rejectWithValue}) {
        const {filters, keepOld, page, limit, ...rest} = params;
        await this._validate();

        const endpointPath = Config.get(`api.routes.${this.service}.list.url`, false);
        const endpointParamResolver = parse(endpointPath);
        const urlParams = Object.fromEntries(endpointParamResolver.filter(p => typeof p === 'object').map(p => {
            const val = rest?.[p.name];
            if (val) {
                delete rest[p.name];
                return [p.name, val];
            }
            return null;
        }).filter(Boolean));
        try {
            const endpoint = API.resolveRoute(this.service, 'list', urlParams);
            const result = await API.request(endpoint.url, omitBy({
                filters,
                page,
                limit
            }, isNil), endpoint.method, get(this.isPublic, 'list', this.isPublic));
            return result;
        }
        catch (e) {
            return rejectWithValue(e);
        }
    }

    async _save(values = {}, {rejectWithValue}) {
        await this._validate();
        let {id, ...data} = values;
        if (!id && isArray(values)) {
            data = values;
        }
        try {
            const endpoint = API.resolveRoute(this.service, id ? 'update' : 'create', {[`${pluralize(this.modelName, 1)}Id`]: id, ...values});
            const result = await API.request(endpoint.url, data, endpoint.method, get(this.isPublic, id ? 'update' : 'create', this.isPublic));
            return result;
        }
        catch (e) {
            return rejectWithValue(e);
        }
    }

    async _delete(id = null, {rejectWithValue}) {
        await this._validate();
        try {
            const endpoint = API.resolveRoute(this.service, 'delete', {[`${pluralize(this.modelName, 1)}Id`]: id});
            await API.request(endpoint.url, {}, endpoint.method, get(this.isPublic, 'delete', this.isPublic));
            return id;
        }
        catch (e) {
            return rejectWithValue(e);
        }
    }

    builder = (adapter, cases) => builder => {

        const overrides = cases && cases(adapter) || {};

        const builders = {
            [this.list.pending]: ['addCase', (state, action) => {
                state.status = 'loading';
            }],
            [this.list.fulfilled]: ['addCase', (state, action) => {
                adapter.setMany(state, action.payload);
                state.status = 'idle';
            }],
            [this.list.rejected]: ['addCase', (state, action) => {
                state.error = action.error;
                state.status = 'error';
                process.env.REACT_APP_ENV === 'local' && console.error(action.error.stack);
            }],
            [this.delete.pending]: ['addCase', (state, action) => {
                state.status = 'loading';
            }],
            [this.delete.fulfilled]: ['addCase', (state, action) => {
                if (!isArray(action.payload)) {
                    action.payload = [action.payload];
                }
                adapter.removeMany(state, action.payload);
                state.status = 'idle';
            }],
            [this.delete.rejected]: ['addCase', (state, action) => {
                state.error = action.data;
                state.status = 'error';
                process.env.REACT_APP_ENV === 'local' && console.error(action.error.stack);
            }],
            [this.getById.pending]: ['addCase', (state, action) => {
                state.status = 'loading';
            }],
            [this.getById.fulfilled]: ['addCase', (state, action) => {
                adapter.upsertOne(state, action.payload);
                state.status = 'idle';
            }],
            [this.getById.rejected]: ['addCase', (state, action) => {
                state.error = action.data;
                state.status = 'error';
                process.env.REACT_APP_ENV === 'local' && console.error(action.error.stack);
            }],
            [this.save.pending]: ['addCase', (state, action) => {
                state.status = 'loading';
            }],
            [this.save.fulfilled]: ['addCase', (state, action) => {
                adapter.upsertOne(state, action.payload);
                state.status = 'idle';
            }],
            [this.save.rejected]: ['addCase', (state, action, t) => {
                state.error = action.data;
                state.status = 'error';
                process.env.REACT_APP_ENV === 'local' && console.error(action.payload);
            }],
            ...overrides
        };

        Object.keys(builders).map(b => {
            const [type, cb] = builders[b];
            if (!type || !cb) {
                return;
            }
            builder[type].apply(this, [b, cb]);
        });
    };
}

export default BaseService;
