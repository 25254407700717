import {createRouterMiddleware} from '@lagunovsky/redux-react-router';
import {getDefaultMiddleware} from '@reduxjs/toolkit';

import {createBrowserHistory} from 'history';
import thunk from 'redux-thunk';
import configureStore from 'store/root/reducerManager';

export const history = createBrowserHistory();

const initialState = {};

const enhancers = [];

const middleware = [
    thunk,
    // createLogger(),
    createRouterMiddleware(history)
];

const store = configureStore(history, enhancers, middleware, initialState);

export default store;
