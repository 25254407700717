import PropTypes from 'prop-types';
import * as React from 'react';

export const ScopeContext = React.createContext(null);

const initialState = [];

/**
 * Scope reducer used to store user scopes data
 * @param {string[]} state
 * @param {Object<{
 *     type: 'get'|'set',
 *     scopes: string[]
 * }>} newData
 * @returns {string[]}
 */
function scopeReducer(state = initialState, {type, ...data}) {
    switch (type) {
        case 'get': {
            return [...state];
        }
        case 'set': {
            return data ? [...data.scopes] : [...initialState];
        }
        default: {
            throw new Error('Unhandled exception');
        }
    }
}

/**
 * Provides breadcrumb data down the JSX tree
 * @param {JSX.Element} children
 * @returns {JSX.Element}
 * @constructor
 */
function ScopeContextProvider({children}) {
    const [state, dispatch] = React.useReducer(scopeReducer, initialState);
    const value = {state, dispatch};
    return (
        <ScopeContext.Provider value={value}>
            {children}
        </ScopeContext.Provider>
    );
}

ScopeContextProvider.propTypes = {
    children: PropTypes.node
};

export default ScopeContextProvider;
