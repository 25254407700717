import useScopes from 'common/hooks/useScopes';
import {useEffect} from 'react';

/**
 * Sets the scopes of the current user
 * @param {string[]} scopes
 */
function useSetScopes(scopes) {
    const {can, state, dispatch} = useScopes();
    useEffect(() => {
        if (scopes) {
            dispatch({type: 'set', scopes});
        }
    }, []);
}

export default useSetScopes;
