import API from 'common/API';
import Messages from 'common/constants/Messages';
import RouteUtil from 'common/RouteUtil';
import SessionStorage from 'common/SessionStorage';
import UserError from 'core/Error/UserError';
import camelCase from 'lodash/camelCase';
import {upperFirst} from 'lodash/string';
import {SubmissionError} from 'redux-form';
import store from 'store';
import {clearToken, loginError, logout, requestLogin} from 'store/auth/AuthActions';
import ConfigService from 'store/config/Config.service';
import OrganizationService from 'store/organization/Organization.service';
import UserService from 'store/user/User.service';

class AuthService {

    async login(props) {
        const {username, password} = props;
        store.dispatch(requestLogin());
        const _self = this;
        try {
            store.dispatch(clearToken());
            const params = {
                username,
                password,
                client_id: process.env.REACT_APP_CLIENT_ID,
                client_secret: process.env.REACT_APP_CLIENT_SECRET
            };

            const endpoint = API.resolveRoute('auth', 'login');
            const response = await API.request(endpoint.url, params, endpoint.method, true);

            if (response.error) {
                if (response.error === 'validation-error') {
                    throw new SubmissionError({
                        _error: response.message,
                        ...response.fields,
                        statusCode: response.status
                    });
                }
                throw response;
            }
            if (response.challenge) {
                if (['inactive-member', 'account-deactivated', 'account-locked', 'renew-password'].includes(response.challenge)) {
                    throw new SubmissionError({
                        _error: `${response.challenge}`,
                        ...response.fields,
                        statusCode: response.status
                    });
                }
            }
            SessionStorage.remove('lock');
            SessionStorage.remove('logout');
            return await this.loginSuccessAction(response);
        }
        catch (e) {
            store.dispatch(clearToken());
            store.dispatch(loginError(e));
            throw e;
        }
    }

    async loginSuccessAction(response) {

        const {userId, access_token, expires, refresh_token, roles, scopes, token_type, challenge} = response;

        await this.saveToken({
            access_token,
            refresh_token,
            roles,
            scopes,
            token_type,
            challenge,
            userId,
            expires
        });

        return response;
    }

    async saveToken(token) {
        await API.saveToken(token);
        if (token && !this.checkChallenges(token)) {
            const additionalHeaders = !token?.refresh_token ? {'x-user-id': token.userId} : undefined;
            store.dispatch(ConfigService.getConfiguration({additionalHeaders}));
            store.dispatch(UserService.getMe({additionalHeaders})).then(({payload}) => {
                store.dispatch(OrganizationService.getById(payload.organization_id));
            });

        }
    }

    checkChallenges(response) {
        if (response.challenge) {
            if (response.challenge === 'change-password') {
                if (window.location.pathname !== RouteUtil.getRoutePath('private.changePassword')) {
                    window.location.href = RouteUtil.getRoutePath('private.changePassword');
                }
            }
            else if (response.challenge === 'validate-mfa') {
                if (window.location.pathname !== RouteUtil.getRoutePath('private.validateMFA')) {
                    window.location.href = RouteUtil.getRoutePath('private.validateMFA');
                }
            }
            return true;
        }
        return false;
    }

    async logout() {
        let headers = null;
        if (API.isSpecialToken()) {
            const token = API.getToken();
            headers = {'x-user-id': token.userId};
        }
        const endpoint = API.resolveRoute('auth', 'logout');
        const response = await API.request(endpoint.url, null, endpoint.method, false, headers);
        if (response.error) {
            throw response;
        }
        store.dispatch(logout());
    }

    async forgotPassword(props) {
        const {username} = props;
        const _self = this;
        const params = {
            username
        };
        const endpoint = API.resolveRoute('auth', 'forgotPassword');
        const response = await API.request(endpoint.url, params, endpoint.method, true);

        if (response.error && response.error === 'validation-error') {
            throw new SubmissionError({
                _error: response.message,
                ...response.fields,
                statusCode: response.status
            });
        }

        return response;
    }

    async resetPassword({resetToken, email, password, password_confirmation}) {
        if (!resetToken) {
            throw new UserError({
                error: Messages.ERROR,
                message: Messages.NO_RESET_PASSWORD_CODE
            });
        }

        const params = {
            password,
            password_confirmation
        };

        const endpoint = API.resolveRoute('auth', 'resetPassword', {resetToken});
        const response = await API.request(endpoint.url, params, endpoint.method, true);

        if (response.error && response.error === 'validation-error') {
            throw new SubmissionError({
                _error: response.message,
                ...response.fields,
                statusCode: response.status
            });
        }
        return response;
    }

    async activateAccount(activateToken, needsPasswordChange) {
        const endpoint = API.resolveRoute('auth', 'activate', {activateToken});
        const response = await API.request(endpoint.url, {
            client_id: process.env.REACT_APP_CLIENT_ID,
            client_secret: process.env.REACT_APP_CLIENT_SECRET,
            needsPasswordChange
        }, endpoint.method, true);
        if (response.error) {
            throw response;
        }
        return response;
    }

    async reactivateAccount(activateToken) {
        const endpoint = API.resolveRoute('auth', 'reactivate', {activateToken});
        const response = await API.request(endpoint.url, {
            client_id: process.env.REACT_APP_CLIENT_ID,
            client_secret: process.env.REACT_APP_CLIENT_SECRET
        }, endpoint.method, true);
        if (response.error) {
            throw response;
        }
        return response;
    }

    async confirmEmail(confirmToken) {
        const endpoint = API.resolveRoute('auth', 'confirmEmail', {confirmToken});
        const response = await API.request(endpoint.url, {
            client_id: process.env.REACT_APP_CLIENT_ID,
            client_secret: process.env.REACT_APP_CLIENT_SECRET
        }, endpoint.method, true);
        if (response.error) {
            throw response;
        }
        return response;
    }

    async requestNewCode(values, service, codeType, isPublic = true) {
        const endpoint = API.resolveRoute(service, `requestNewCode${upperFirst(camelCase(codeType))}`);
        const response = await API.request(endpoint.url, values, endpoint.method, isPublic);
        if (response.error) {
            throw response;
        }
        return response;
    }

    async changePassword(values) {
        const endpoint = API.resolveRoute('auth', 'changePassword');
        const response = await API.request(endpoint.url, values, endpoint.method, false);
        if (response.error) {
            throw response;
        }
        return response;
    }

    async enableMFA(values) {
        const endpoint = API.resolveRoute('auth', 'enableMFA');
        const response = await API.request(endpoint.url, values, endpoint.method, false);

        if (response.error) {
            throw response;
        }
        return response;
    }

    async validateMFA(values) {

        const endpoint = API.resolveRoute('auth', 'validateMFA');
        const response = await API.request(endpoint.url, values, endpoint.method, false);

        if (response.error) {
            throw response;
        }

        await this.loginSuccessAction(response);
    }

    async lockScreen(values) {

        const endpoint = API.resolveRoute('auth', 'lock');
        const response = await API.request(endpoint.url, values, endpoint.method, false);

        if (response.error) {
            throw response;
        }

        await API.saveToken(response);

        return response;
    }

    async unlockScreen(values) {

        const endpoint = API.resolveRoute('auth', 'unlock');
        const response = await API.request(endpoint.url, values, endpoint.method, false);

        if (response.error) {
            throw response;
        }
        SessionStorage.remove('lock');
        SessionStorage.remove('logout');
        return response;
    }

    async sendMFACode({method, userId}) {
        const endpoint = API.resolveRoute('auth', 'sendMFACode');
        await API.request(endpoint.url, {
            userId,
            method
        }, endpoint.method, false);
    }

}

export default new AuthService();
