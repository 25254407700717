/**
 * LocationService definitions
 */

import {createAsyncThunk} from '@reduxjs/toolkit';
import API from 'common/API';
import BaseService from 'store/BaseService';
import store from 'store/index';

class LocationService extends BaseService {

    constructor(props) {
        super({
            model: 'location',
            service: 'location',
            isPublic: false
        });
        this._updateCredit = this._updateCredit.bind(this);
        this.updateCredit = createAsyncThunk(`${this.model}/updateCredit`, this._updateCredit);
    }

    _updateCredit = async ({data, props}) => {
        await this._validate();
        const {url, method} = API.resolveRoute('organization', 'transferCredit', props);
        const result = await API.request(url, data, method, false);
        if (!result && !result.error) {
            throw result;
        }
        store.dispatch(this.getById(props.id));
    };
}

export default new LocationService();
