import {DialogFormContext} from 'common/DialogFormContext';
import * as React from 'react';
import {useDispatch} from 'react-redux';
import {reset, submit} from 'redux-form';

/**
 * Creates event hooks to support modals and confirmation dialog chains that supports redux-form.
 * Functions can be used on the template, and hooks are a handy skeleton for the business logic of modals dialog or other actions that require user input.
 * @param {string} formName
 * @returns {Object<{
 *  form: string,
 *  submitForm: function(): Promise<void>,
 *  resetForm: function(): Promise<void>,
 *  setForm: function(string): void,
 *  state: Object,
 *  dispatch: function(Object): void
 * }>}
 */
function useDialogForm(formName = null) {

    const reduxDispatch = useDispatch();
    const context = React.useContext(DialogFormContext);

    if (context === undefined) {
        throw new Error('useDialogForm should be used within a DialogFormContextProvider');
    }
    const {state, dispatch} = context;

    const setForm = React.useCallback((formName) => {
        dispatch({
            type: 'set', formName
        });
    }, [state]);

    const form = state?.formName;

    const submitForm = React.useCallback(async () => {
        if (!form) {
            return Promise.resolve();
        }
        return reduxDispatch(submit(form));
    }, [state]);

    const resetForm = React.useCallback(async () => {
        if (!form) {
            return Promise.resolve();
        }
        return reduxDispatch(reset(form));
    }, [state]);

    React.useEffect(() => {
        if (formName) {
            setForm(formName);
        }
    }, [formName]);

    return {setForm, submitForm, resetForm, form, state, dispatch};
}

export default useDialogForm;
