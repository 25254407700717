/* eslint-disable function-paren-newline */
import defaultTo from 'lodash/defaultTo';
import get from 'lodash/get';
import first from 'lodash/first';
import intersection from 'lodash/intersection';

import moment from 'moment';
import {createSelector} from 'reselect';

import UserSelectors from 'store/user/User.selectors';

const emptyArray = [];
const emptyObject = {};

const getIsState = (state, level) => level || '';
const getCanState = (state, scopes) => scopes || [];
const getAuthState = state => get(state, 'auth', emptyObject);

export const can = createSelector(
    [
        getAuthState,
        getCanState
    ], (auth, scopes) => intersection(typeof scopes === 'string' ? [scopes] : scopes, get(auth, 'scopes', emptyArray)).length > 0
);

export const getRootStateAuth = createSelector(
    [getAuthState],
    authState => authState || emptyObject
);

class AuthSelectorFactory {
    getAuth = getRootStateAuth;
    getCurrentUser = createSelector(
        this.getAuth,
        auth => get(auth, 'userId')
    );
    getAccessToken = createSelector(
        this.getAuth,
        auth => get(auth, 'access_token', null)
    );
    getRefreshToken = createSelector(
        this.getAuth,
        auth => get(auth, 'refresh_token', null)
    );

    getExpiresAt = createSelector(
        this.getAuth,
        auth => moment.unix(get(auth, 'expires', moment().subtract(1, 'hour').utc().unix())).utc()
    );

    getIsTokenExpired = createSelector(
        this.getAuth,
        auth => {
            const expiration = get(auth, 'expires', moment().subtract(1, 'hour'));
            return expiration && moment.utc().isAfter(moment.unix(expiration).utc());
        }
    );
    getAuthStatus = createSelector(
        this.getAuth,
        auth => get(auth, 'status', 'unauthenticated')
    );

    getIsTokenRefreshing = createSelector(
        this.getAuth,
        auth => get(auth, 'isRefreshing', false)
    );

    getIsAuthenticated = createSelector(
        [this.getAuthStatus, this.getAccessToken],
        (status, token) => Boolean(status === 'authenticated' && token !== null)
    );
    getWelcomeMessage = createSelector(
        this.getAuth,
        auth => get(auth, 'welcome', null)
    );
    getChallenge = createSelector(
        this.getAuth,
        auth => get(auth, 'challenge', null)
    );

    getRoles = createSelector(
        this.getAuth,
        auth => defaultTo(get(auth, 'roles', emptyArray), emptyArray)
    );
    getRole = createSelector(
        this.getAuth,
        auth => first(defaultTo(get(auth, 'roles', emptyArray), emptyArray))
    );
    getIsPatient = createSelector(
        this.getRole,
        role => role === 'patient'
    );

    getIsStaff = createSelector(
        this.getRole,
        role => role === 'staff'
    );

    getScopes = createSelector(
        [this.getAuth, UserSelectors.getUser],
        (auth, user) => {
            const scopes = defaultTo(get(auth, 'scopes'), emptyArray);

            if (get(user, 'organization.preferences.demo_organization', false)) {
                return scopes.filter(scope => !scope.includes('test-result'));
            }

            if (get(user, 'organization.preferences.hide_assessment_results', false)) {
                return scopes.filter(scope => !scope.includes('test-result'));
            }

            if (!get(user, 'organization.preferences.display_patient_results', true) && scopes.includes('isPatient')) {
                return scopes.filter(scope => !scope.includes('test-result'));
            }

            return scopes;
        }
    );

    getIsInternal = createSelector(
        this.getScopes,
        scopes => !scopes.includes('internal-only')
    );

    getIsClient = createSelector(
        this.getScopes,
        scopes => !scopes.includes('internal-only')
    );

}

export default new AuthSelectorFactory();
