import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    data: null,
    languages: null,
    status: 'idle',
    error: null
};

const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        getLangSuccess: (state, action) => {
            state.languages = action.payload;
        }
    },
    extraReducers: builder => {
        builder
            .addCase('config/get/pending', (state, action) => {
                state.status = 'loading';
            })
            .addCase('config/get/fulfilled', (state, action) => {
                Object.assign(action.payload, {yes_no: {true: 'yes', false: 'no'}});
                state.data = action.payload;
                state.status = 'idle';
            })
            .addCase('config/get/rejected', (state, action) => {
                state.data = null;
                state.status = 'error';
                state.error = action.payload;
            });
    }
});

export const {getLangSuccess} = configSlice.actions;

export default configSlice;
