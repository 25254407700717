import {LoadingButton} from '@mui/lab';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import useDialogForm from 'common/hooks/useDialogForm';
import {Close} from 'mdi-material-ui';
import PropTypes from 'prop-types';
import React, {Suspense} from 'react';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import UISelectors from 'store/ui/UI.selectors';
import {closeLazyDialog} from 'store/ui/UI.slice';

const LazyDialog = props => {
    const {
        classes,
        theme,
        open,
        component,
        ComponentProps,
        title,
        onClose,
        canClose,
        canCancel,
        cancelButtonLabel,
        CancelButtonProps,
        onCancelClick,
        canConfirm,
        confirmButtonLabel,
        ConfirmButtonProps = {},
        onConfirmClick,
        isLoading = false,
        closeDialog,
        t,
        ...rest
    } = props;

    const {form, submitForm, resetForm} = useDialogForm();

    const handleOnclose = (e) => {
        typeof onClose === 'function' && onClose(e);
        form && resetForm();
    };

    const handleCancelClick = e => {
        typeof onCancelClick === 'function' && onCancelClick(e);
        closeDialog();
        handleOnclose(e);
        form && resetForm();
    };

    const handleConfirmClick = e => {
        typeof onConfirmClick === 'function' && onConfirmClick(e);
        if (form) {
            submitForm()
                .then((r) => {
                    closeDialog();
                    handleOnclose(e);
                })
                .catch(e => {
                });
        }
    };

    if (!component) {
        return null;
    }

    return (

        <Dialog
            open={open}
            className={classes.root}
            onClose={handleOnclose}
            maxWidth={'md'}
            fullWidth
        >
            <DialogTitle className={classes.title}>
                {title}
                {canCancel && (
                    <Close
                        className={classes.closeIcon}
                        onClick={handleCancelClick}
                    />
                )}
            </DialogTitle>
            <DialogContent
                className={classes.body}
                sx={{paddingTop: `${theme.spacing(!title ? 3 : 0)} !important`}}
            >
                <Suspense fallback={t('common:loading', {entity: '...'})}>
                    <Box component={component()} {...ComponentProps} />
                </Suspense>
            </DialogContent>
            <DialogActions className={classes.actions}>
                {canCancel && (
                    <Button
                        variant={'outlined'}
                        color={'primary'}
                        type={'button'}
                        className={classes.cancelButton}
                        {...CancelButtonProps}
                        onClick={handleCancelClick}
                    >{cancelButtonLabel || t('common:cancel')}</Button>
                )}

                {canConfirm && (
                    <LoadingButton
                        color={'primary'}
                        variant={'contained'}
                        loading={isLoading}
                        className={classes.confirmButton}
                        {...ConfirmButtonProps}
                        onClick={handleConfirmClick}
                    >{confirmButtonLabel || t('common:ok')}</LoadingButton>
                )}
            </DialogActions>
        </Dialog>
    );
};

LazyDialog.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    component: PropTypes.any,
    ComponentProps: PropTypes.object,
    title: PropTypes.any,
    onClose: PropTypes.func,
    canClose: PropTypes.bool,
    canCancel: PropTypes.bool,
    cancelButtonLabel: PropTypes.string,
    CancelButtonProps: PropTypes.object,
    onCancelClick: PropTypes.func,
    canConfirm: PropTypes.bool,
    confirmButtonLabel: PropTypes.string,
    ConfirmButtonProps: PropTypes.object,
    onConfirmClick: PropTypes.func,
    isLoading: PropTypes.bool,
    closeDialog: PropTypes.func,
    t: PropTypes.func,
    open: PropTypes.bool
};

const styles = theme => ({
    root: {},
    title: {
        padding: theme.spacing(2, 3),
        textTransform: 'capitalize',
        color: theme.palette.primary.main
    },
    closeIcon: {
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(2),
        cursor: 'pointer'
    },
    body: {
        position: 'relative'
    },
    actions: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'space-between',
        justifyContent: 'space-between',
        padding: theme.spacing(4)
    },
    cancelButton: {
        minWidth: theme.spacing(15)
    },
    confirmButton: {
        minWidth: theme.spacing(15)
    }
});

const mapStateToProps = (state, ownProps) => ({
    ...UISelectors.getLazyDialog(state)
});

const mapDispatchToProps = dispatch => ({
    closeDialog: () => dispatch(closeLazyDialog())
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles, {withTheme: true})(LazyDialog)));
