/* eslint no-console: ["error", { allow: ["warn", "error"] }] */

/**
 * CommonJS module for Storage object.
 * @module common/Storage
 * @type {StorageFactory}
 */

/**
 * Storage Constructor
 * @returns {StorageFactory} Storage object
 */

/**
 * Compass Browser Storage Constructor
 * @constructor
 * @param {string} [type=sessionStorage] Type of browser storage object sessionStorage or localStorage
 * @returns {StorageFactory} Returns Storage object
 */
const StorageFactory = function StorageFactory(type) {
    /**
     * @property {string} storage Selected storage
     * @default null
     */
    this.storage = null;

    const storageType = type || 'sessionStorage';

    return this.initialize(storageType);
};

/**
 * Storage initialize.
 * @param  {string} type Storage type (sessionStorage|localStorage).
 * @return {object}      Storage object.
 */
StorageFactory.prototype.initialize = function (type) {
    this.storage = type;
    return this;
};

/**
 * Check if storage is available in current browser.
 * @param {string} [type] Type of the storage object to check. Possible values are sessionStorage, localStorage
 * @return {boolean} Returns true/false if browser has storage support for selected storage type.
 */
StorageFactory.prototype.isAvailable = function (type) {

    const storageType = type || this.storage;
    try {
        const storage = window[storageType];
        const x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    }
    catch (e) {
        console.warn(e);
        return false;
    }
};

/**
 * Get item from storage.
 * @param  {string} key key of the stored object.
 * @param {mixed} [defaultValue] default value for the key
 * @return {string} Stored value for the key.
 */
StorageFactory.prototype.get = function (key, defaultValue) {
    let value = defaultValue;
    if (this.isAvailable(this.storage)) {
        value = window[this.storage].getItem(key);
    }
    // noinspection JSValidateTypes
    return value;
};

/**
 * Get item from storage.
 * @return {string} Stored value for the key.
 */
StorageFactory.prototype.all = function () {
    let value = null;
    if (this.isAvailable(this.storage)) {
        value = window[this.storage];
    }
    // noinspection JSValidateTypes
    return value;
};
/**
 * Set item in storage.
 * @param {string} key  Name of the object to be stored.
 * @param {string} value Value of the object to be stored.
 * @return {StorageFactory} Storage object.
 */
StorageFactory.prototype.set = function (key, value) {
    if (this.isAvailable(this.storage)) {
        window[this.storage].setItem(key, value);
    }
    return this;
};

/**
 * Get item from storage.
 * @param  {string} key key of the stored object.
 * @return {string} Stored value for the key.
 */
StorageFactory.prototype.has = function (key) {

    if (!this.isAvailable(this.storage)) {
        // noinspection JSValidateTypes
        return false;
    }

    // noinspection JSValidateTypes
    return window[this.storage].getItem(key) !== null;
};

/**
 * Remove object from storage.
 * @param  {string} key Storage key identifier.
 */
StorageFactory.prototype.remove = function (key) {
    if (this.isAvailable(this.storage)) {
        window[this.storage].removeItem(key);
    }
};

/**
 * Clear storage.
 * @return {boolean} Returns true if browser supports storage object
 */
StorageFactory.prototype.clear = function () {
    if (this.isAvailable(this.storage)) {
        window[this.storage].clear();
    }
    return true;
};

export default StorageFactory;
