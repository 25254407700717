import React, {useEffect, useState} from 'react';
import {useRoutes} from 'react-router-dom';
import {useCurrentRoutes} from 'common/hooks/useCurrentRoutes';

const Base = () => {
    const [routes, setRoutes] = useState([]);

    useEffect(() => {
        const r = useCurrentRoutes();
        setRoutes(r);
    }, []);

    const Element = useRoutes(routes);
    return Element;
};

export default Base;
