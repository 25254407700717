import useQueryString from 'common/hooks/useQueryString';
import useSetScopes from 'common/hooks/useSetScopes';
import some from 'lodash/some';
import first from 'lodash/first';
import memoizeOne from 'memoize-one';
import * as PropTypes from 'prop-types';
import React from 'react';
import {connect, shallowEqual, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import AuthSelectors from 'store/auth/AuthSelectors';
import UserSelectors from 'store/user/User.selectors';
import NotAuthorized from 'views/modules/Error/NotAuthorized';

const Authorized = props => {
    const {componentScopes, children, ...rest} = props;
    const params = useParams();
    const query = useQueryString();
    const scopes = useSelector(AuthSelectors.getScopes);
    const role = useSelector(AuthSelectors.getRole);
    const user = useSelector(UserSelectors.getUser, shallowEqual);
    if (role === 'patient') {
        scopes.push('isPatient');
    }
    useSetScopes(Object.values(scopes));

    const checkScopeAuthorization = memoizeOne(() => {
        const hasScope = componentScopes && componentScopes.length && some(componentScopes.map(scope => scopes.includes(scope)));
        if (user && ((scopes && hasScope) || !componentScopes)) {
            return React.Children.map(children, child => React.cloneElement(child, {
                currentUser: user,
                scopes,
                params,
                query,
                ...rest
            }));
        }
        return <NotAuthorized />;
    });

    const isAuthorized = checkScopeAuthorization();
    return isAuthorized;
};

Authorized.propTypes = {
    componentScopes: PropTypes.any,
    scopes: PropTypes.array,
    user: PropTypes.object,
    children: PropTypes.any
};

export default Authorized;
