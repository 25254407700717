import {createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import {getInitials} from 'common/helpers/Str';
import moment from 'moment';
import UserService from 'store/user/User.service';
import _ from 'lodash';

export const usersAdapter = createEntityAdapter();

const initialState = usersAdapter.getInitialState({
    ids: [],
    entities: {},
    status: 'idle',
    error: null,
    pagination: null
});

const normalizeUser = ({roles = [], ...user}) => ({
    ...user,
    role: _.head(roles),
    roles: roles.map(r => r.id),
    fullname: `${_.get(user, 'firstName', '')} ${_.get(user, 'lastName', '')}`,
    initials: getInitials(`${_.get(user, 'firstName', '')} ${_.get(user, 'lastName', '')}`)
});

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        clearUser: state => initialState
    },
    extraReducers: UserService.builder(usersAdapter, (adapter) => ({
        'user/list/fulfilled': ['addCase', (state, action) => {
            const normalizedUsers = action.payload?.map(normalizeUser);
            adapter.upsertMany(state, normalizedUsers);
            state.status = 'idle';
        }],
        'user/getById/fulfilled': ['addCase', (state, action) => {
            const normalizedUser = normalizeUser(action.payload);
            adapter.upsertOne(state, normalizedUser);
            state.status = 'idle';
        }],
        'user/upsert/fulfilled': ['addCase', (state, action) => {
            const normalizedUser = normalizeUser(action.payload);
            adapter.upsertOne(state, normalizedUser);
            state.status = 'idle';
        }],
        'user/bulkCertify/fulfilled': ['addCase', (state, action) => {
            const userIds = [...action.payload];
            userIds.map(userId => {
                state.entities[userId].needs_recert = false;
                state.entities[userId].recert_date = moment().utc().toISOString();
            });
            state.status = 'idle';
        }],
        'user/bulkCertify/pending': ['addCase', (state, action) => {
            state.status = 'recertifying';
        }],
        'user/bulkCertify/rejected': ['addCase', (state, action) => {
            state.status = 'error';
            state.error = action.payload;
        }]
    }))
});

export const {clearUser} = userSlice.actions;

export default userSlice;
