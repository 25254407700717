import {createDraftSafeSelector} from '@reduxjs/toolkit';

import _ from 'lodash';
import {usersAdapter} from 'store/user/User.slice';

const emptyArray = [];
const emptyObject = {};

const getRootState = state => state.user || usersAdapter.getInitialState();
// AuthSelectors are not used here to avoid circular dependencies
const getUserId = (state) => _.get(state, 'auth.userId');

const {
    selectAll,
    selectById,
    selectCount
} = usersAdapter.getSelectors(getRootState);

class UserSelectorFactory {
    getState = getRootState;
    getOrganizationId = (state, id) => id || null;
    getUsers = selectAll;
    getUsersCount = selectCount;
    getById = (state, id) => selectById(state, id || getUserId(state));
    // We use createSelector here instead of createDraftSelector because we manipulated memoized selector.
    // for more info look at https://redux-toolkit.js.org/api/createSelector createDraftSafeSelector section.
    getUser = this.getById;
    getUsersByOrg = createDraftSafeSelector(
        [this.getUsers, this.getOrganizationId],
        (state, organizationId) => _.chain(state).filter({organization_id: organizationId}).value()
    );
    getIsActive = createDraftSafeSelector(
        [this.getUser],
        user => Boolean(_.get(user, 'status', 0) === 1)
    );
    getIsBlocked = createDraftSafeSelector(
        [this.getUser],
        user => Boolean(_.get(user, 'status', 0) === 2)
    );
    getFullname = createDraftSafeSelector(
        [this.getUser],
        user => _.get(user, 'fullName', '')
    );
    getInitials = createDraftSafeSelector(
        [this.getUser],
        user => _.get(user, 'initials', '??')
    );
    getNotifications = createDraftSafeSelector(
        [this.getUser],
        user => _.get(user, 'notifications', emptyObject)
    );
    getUserRoleName = createDraftSafeSelector(
        [this.getUser],
        user => _.get(user, 'role.name', '')
    );
    getPagination = createDraftSafeSelector(
        this.getState,
        state => _.get(state, 'pagination', emptyObject)
    );
    getStatus = createDraftSafeSelector(
        [this.getState],
        state => _.get(state, 'status', 'empty')
    );
    getError = createDraftSafeSelector(
        [this.getState],
        state => _.get(state, 'error', null)
    );
}

export default new UserSelectorFactory();
