import PropTypes from 'prop-types';
import * as React from 'react';

export const DialogFormContext = React.createContext(null);

const initialState = null;

/**
 * Dialog reducer used to store dialog form data
 * @param {Object} state
 * @param {Object} newData
 * @returns {Object}
 */
function dialogFormReducer(state = initialState, {type, ...data}) {
    switch (type) {
        case 'get': {
            return state;
        }
        case 'set': {
            return data || initialState;
        }
        default: {
            throw new Error('Unhandled exception');
        }
    }
}

/**
 * Provides DialogForm data down the JSX tree
 * @param {JSX.Element} children
 * @returns {JSX.Element}
 * @constructor
 */
function DialogFormContextProvider({children}) {
    const [state, dispatch] = React.useReducer(dialogFormReducer, initialState);
    const value = {state, dispatch};
    return (
        <DialogFormContext.Provider value={value}>
            {children}
        </DialogFormContext.Provider>
    );
}

DialogFormContextProvider.propTypes = {
    children: PropTypes.node
};

export default DialogFormContextProvider;
