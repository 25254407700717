export default {
    base: {
        path: '/',
        /**
         * PreLogin Pages
         */
        login: {
            path: '/login'
        },
        forgotPassword: {
            path: '/forgot-password'
        },
        resetPassword: {
            path: '/reset-password/:code?'
        },
        /**
         * ToDo
         * Prelogin Pages Redesign Pending
         */
        changePassword: {
            path: '/change-password'
        },
        unlockAccount: {
            path: '/unlock-account/:code?'
        },
        confirmAccount: {
            path: '/confirm-account/:code?'
        },
        confirmEmail: {
            path: '/confirm-email/:code?'
        },
        requestNewCode: {
            path: '/request-new-code'
        },
        validateMFA: {
            path: '/mfa/validate'
        },
        tos: {
            path: '/terms/:language?'
        },
        privacy: {
            path: '/privacy/:language?'
        },
        reactivateAccount: {
            path: '/reactivate/:code?'
        },
        /**
         * Private Routes
         */
        modules: {
            // routes that only allowed administrator role
            internal: {
                path: '/admin',
                permissions: {
                    path: '/permissions',
                    component: 'Permission/PermissionListContainer',
                    props: {}
                },
                roles: {
                    path: '/roles',
                    component: 'Role/RoleListContainer',
                    props: {}
                },
                locales: {
                    path: '/locales',
                    component: 'Translation/TranslationsContainer',
                    props: {}
                },
                testResults: {
                    path: '/test-results',
                    component: 'TestResult/TestResultListContainer'
                },
                modules: {
                    path: '/modules',
                    component: 'Modules/ModulesListContainer',
                    props: {}
                }
            },
            // routes for logged in user
            private: {
                dashboard: {
                    path: '/',
                    component: 'Dashboard/DashboardContainer',
                    props: {}
                },
                changePassword: {
                    path: '/my-profile/change-password',
                    component: 'ChangePassword/ChangePasswordContainer',
                    props: {}
                },
                // Organizations
                organizations: {
                    path: '/organizations',
                    component: 'Organization/OrganizationListContainer',
                    props: {
                        scope: 'list-organizations'
                    }
                },
                viewOrganization: {
                    path: '/organizations/view/:orgId',
                    component: 'Organization/OrganizationViewContainer',
                    props: {
                        scope: 'view-organization'
                    }
                },
                viewLocation: {
                    path: '/organizations/view/:orgId/location/:locationId',
                    component: 'Location/LocationViewContainer',
                    props: {
                        scope: 'view-location'
                    }
                },
                // Users
                users: {
                    path: '/users',
                    component: 'User/UserListContainer',
                    props: {
                        scope: 'list-users'
                    }
                },
                // Patients
                patients: {
                    path: '/patients',
                    component: 'Patient/PatientListContainer',
                    props: {
                        scope: 'list-patients'
                    }
                },
                viewPatient: {
                    path: '/patients/:patientId/view',
                    component: 'Patient/PatientViewContainer',
                    props: {
                        scope: 'view-patient'
                    }
                },
                viewPatientHealthProfile: {
                    path: '/patients/:patientId/view-health-profile',
                    component:
                        'Patient/HealthProfile/PatientHealthProfileViewContainer',
                    props: {
                        scope: 'view-patient'
                    }
                },
                patientDocuments: {
                    path: '/patients/:patientId/view-documents',
                    component:
                        'Patient/Document/PatientDocumentCategoriesListContainer',
                    props: {
                        scope: 'view-patient'
                    }
                },
                viewPatientDocument: {
                    path: '/patients/:patientId/view-documents/:categoriesId',
                    component: 'Patient/Document/PatientDocumentListContainer',
                    props: {
                        scope: 'view-patient'
                    }
                },
                viewPatientVisits: {
                    path: '/patients/:patientId/view-visits',
                    component:
                        'Patient/Visits/PatientVisitsListContainer',
                    props: {
                        scope: 'list-visits'
                    }
                },
                viewPatientLabResults: {
                    path: '/patients/:patientId/view-lab-results',
                    component:
                        'Patient/Lab/LabResultsContainer',
                    props: {
                        scope: 'list-lab-results'
                    }
                },
                patientAssessments: {
                    path: '/patients/:patientId/view-assessments',
                    component:
                        'Patient/Assessment/PatientAssessmentListContainer',
                    props: {
                        scope: 'view-patient-questionnaire'
                    }
                },
                editPatientAssessment: {
                    path: '/patients/:patientId/edit-assessments/:questionnaireId',
                    component:
                        'Patient/Assessment/PatientAssessmentEditContainer',
                    props: {
                        scope: 'update-patient-questionnaire'
                    }
                },
                viewPatientAssessmentNPI: {
                    path: '/patients/:patientId/edit-assessment-npi/:questionnaireId',
                    component:
                        'Patient/Assessment/PatientAssessmentNPIContainer',
                    props: {
                        scope: 'view-patient-questionnaire'
                    }
                },
                editPatientAssessmentPSQI: {
                    path: '/patients/:patientId/edit-assessment-psqi/:questionnaireId',
                    component:
                        'Patient/Assessment/PatientAssessmentPSQIContainer',
                    props: {
                        scope: 'update-patient-questionnaire'
                    }
                },
                viewPFTAssessment: {
                    path: '/patients/:patientId/view-assessment-pft/:questionnaireId',
                    component:
                        'Patient/Assessment/PFTViewContainer',
                    props: {
                        scope: 'view-patient-questionnaire'
                    }
                },
                editPFTAssessment: {
                    path: '/patients/:patientId/edit-assessment-pft/:questionnaireId',
                    component:
                        'Patient/Assessment/PFTEditContainer',
                    props: {
                        scope: 'update-patient-questionnaire'
                    }
                },
                viewPatientAssessment: {
                    path: '/patients/:patientId/view-assessment/:questionnaireId',
                    component:
                        'Patient/Assessment/PatientAssessmentViewContainer',
                    props: {
                        scope: 'view-patient-questionnaire'
                    }
                },
                editPatientAssessmentAIADL: {
                    path: '/patients/:patientId/edit-assessment-aiadl/:questionnaireId',
                    component:
                        'Patient/Assessment/AIADLEditContainer',
                    props: {
                        scope: 'update-patient-questionnaire'
                    }
                },
                viewPatientAssessmentAIADL: {
                    path: '/patients/:patientId/view-assessment-aiadl/:questionnaireId',
                    component:
                        'Patient/Assessment/AIADLViewContainer',
                    props: {
                        scope: 'view-patient-questionnaire'
                    }
                },
                editPatientAssessmentGLTEQ: {
                    path: '/patients/:patientId/edit-assessment-glteq/:questionnaireId',
                    component:
                        'Patient/Assessment/GLTEQEditContainer',
                    props: {
                        scope: 'update-patient-questionnaire'
                    }
                },
                viewPatientAssessmentGLTEQ: {
                    path: '/patients/:patientId/view-assessment-glteq/:questionnaireId',
                    component:
                        'Patient/Assessment/GLTEQViewContainer',
                    props: {
                        scope: 'view-patient-questionnaire'
                    }
                },
                editPatientAssessmentSFS: {
                    path: '/patients/:patientId/edit-assessment-sfs/:questionnaireId',
                    component:
                        'Patient/Assessment/SFSEditContainer',
                    props: {
                        scope: 'update-patient-questionnaire'
                    }
                },
                viewPatientAssessmentSFS: {
                    path: '/patients/:patientId/view-assessment-sfs/:questionnaireId',
                    component:
                        'Patient/Assessment/SFSViewContainer',
                    props: {
                        scope: 'view-patient-questionnaire'
                    }
                },
                editPatientAssessmentPhyNeuro: {
                    path: '/patients/:patientId/edit-assessment-phy-neuro/:questionnaireId',
                    component:
                        'Patient/Assessment/PhyNeuroEditContainer',
                    props: {
                        scope: 'update-patient-questionnaire'
                    }
                },
                viewPatientAssessmentPhyNeuro: {
                    path: '/patients/:patientId/view-assessment-phy-neuro/:questionnaireId',
                    component:
                        'Patient/Assessment/PhyNeuroViewContainer',
                    props: {
                        scope: 'view-patient-questionnaire'
                    }
                },
                displayPatientAssessment: {
                    path: '/patients/:patientId/view-assessments/:questionnaireId',
                    component:
                        'Patient/Assessment/PatientAssessmentDisplayContainer',
                    props: {
                        scope: 'view-patient-questionnaire'
                    }
                },
                displayPatientAssessmentNPI: {
                    path: '/patients/:patientId/view-assessments-npi/:questionnaireId',
                    component:
                        'Patient/Assessment/PatientAssessmentNPIDisplayContainer',
                    props: {
                        scope: 'view-patient-questionnaire'
                    }
                },
                patientTestResults: {
                    path: '/patients/:patientId/test-results',
                    component:
                        'Patient/TestResult/PatientTestResultListContainer',
                    props: {
                        scope: 'view-patient'
                    }
                },
                viewPatientTestResults: {
                    path: '/patients/:patientId/test-results/:testResultId',
                    component:
                        'Patient/TestResult/PatientTestResultViewContainer',
                    props: {
                        scope: 'view-patient'
                    }
                },
                patientAppointments: {
                    path: '/patients/:patientId/view-appointments',
                    component:
                        'Patient/Appointment/PatientAppointmentListContainer',
                    props: {
                        scope: 'view-patient'
                    }
                },
                assignAssessments: {
                    path: '/assessments/assign-assessments/',
                    component:
                        'Patient/Assessment/AssignAssessmentsContainer',
                    props: {
                        scope: 'list-assign-assessments'
                    }
                },
                test: {
                    path: '/test',
                    component:
                        'Patient/HealthProfile/History/HistoryFormContainer'
                },
                viewPatientVitals: {
                    path: '/patients/:patientId/vitals',
                    component:
                        'Patient/Vitals/PatientVitalsListContainer',
                    props: {
                        scope: 'list-vitals'
                    }
                }
                // documentsCategories: {
                //     path: '/documents-categories/:patientId',
                //     component: 'Documents/DocumentsCategoriesContainer',
                //     props: {
                //         scope: 'view-patient'
                //     }
                // },
                // documentsList: {
                //     path: '/view-patient/:docCategoryName',
                //     component: 'Documents/DocumentsViewContainer',
                //     props: {
                //         scope: 'view-patient'
                //     }
                // }
            }
        }
    }
};
