import {alpha, tooltipClasses} from '@mui/material';
import {createTheme, responsiveFontSizes} from '@mui/material/styles';

const variables = () => ({
    fontPrimary: 'sans-serif',
    fontSecondary: 'Publico Text',
    fontRegular: 'GT America'
});

const themes = theme => {
    const baseTheme = {
        ...createTheme(),
        variables: variables()
    };
    const colors = createTheme({
        ...baseTheme,
        palette: {
            mode: theme,
            primary: baseTheme.palette.augmentColor({
                color: {
                    contrastText: '#FAFAEE',
                    main: '#1B2B73',
                    dark: '#5668c2',
                    light: '#0e1b56'
                }
            }),
            secondary: baseTheme.palette.augmentColor({
                color: {
                    contrastText: '#fff',
                    main: '#48A9DC'
                }
            }),
            error: {
                light: '#e57373',
                main: '#f44336',
                dark: '#d32f2f',
                contrastText: '#fff'
            },
            success: {
                light: '#99d066',
                main: '#689f38',
                dark: '#387002',
                contrastText: '#fff'
            },
            warning: {
                light: '#ffad42',
                main: '#f57c00',
                dark: '#bb4d00',
                contrastText: '#fff'
            },
            info: {
                light: '#80d6ff',
                main: '#42a5f5',
                dark: '#0077c2',
                contrastText: '#fff'
            },
            trendChart: {
                negative: baseTheme.palette.augmentColor({
                    color: {
                        main: alpha(baseTheme.palette.error.main, .2),
                        contrastText: 'rgba(0, 0, 0, 0.8)'
                    }
                }),
                positive: baseTheme.palette.augmentColor({
                    color: {
                        main: alpha(baseTheme.palette.success.main, .2),
                        contrastText: 'rgba(0, 0, 0, 0.8)'
                    }
                })
            },
            altoida: {
                dusk: baseTheme.palette.augmentColor({
                    name: 'dusk',
                    color: {
                        main: '#291987',
                        contrastText: '#FFF'
                    }
                }),
                sand: baseTheme.palette.augmentColor({
                    name: 'sand',
                    color: {
                        main: '#FAFAEE',
                        contrastText: '#131313'
                    }
                }),
                blossom: baseTheme.palette.augmentColor({
                    name: 'blossom',
                    color: {
                        main: '#F1A2FF',
                        contrastText: '#000'
                    }
                }),
                spring: baseTheme.palette.augmentColor({
                    name: 'spring',
                    color: {
                        main: '#42D864',
                        contrastText: '#000'
                    }
                }),
                dawn: baseTheme.palette.augmentColor({
                    name: 'dawn',
                    color: {
                        main: '#FFA87C',
                        contrastText: '#000'
                    }
                }),
                rain: baseTheme.palette.augmentColor({
                    name: 'rain',
                    color: {
                        main: '#AAE5FA',
                        contrastText: '#291987'
                    }
                })
            }
        }
    });

    const opposizeMode = theme === 'light' ? 'main' : 'dark';

    const outlinedShrinkLabelOverride = {
        color: colors.palette.common.black,
        padding: baseTheme.spacing(0, 1),
        '&:before': {
            content: '""',
            position: 'absolute',
            background: colors.palette.common.white,
            fontSize: 0,
            border: 0,
            height: 20,
            width: '100%',
            top: theme === 'dark' ? 5 : 2,
            borderRadius: 20,
            left: 0,
            zIndex: '-1'
        }
    };
    const typography = createTheme({
        ...colors,
        typography: {
            fontSize: 14,
            h1: {
                fontSize: '3rem'
            },
            h2: {
                fontSize: '2.8rem'
            },
            h3: {
                fontSize: '2.5rem'
            },
            h4: {
                fontSize: '2.1rem'
            },
            h5: {
                fontSize: '1.8rem'
            },
            h6: {
                fontSize: '1.25rem'
            }
        }
    });

    const components = createTheme({
        ...colors,
        ...responsiveFontSizes(typography),
        components: {
            MuiCssBaseline: {
                styleOverrides: {
                    'html, body': {
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column'
                    },
                    html: {
                        fontFamily: baseTheme.variables.fontPrimary,
                        lineHeight: 1.15,
                        webkitTextSizeAdjust: '100%',
                        webkitTapHighlightColor: 'transparent'
                    },
                    body: {
                        margin: 0,
                        fontSize: 12,
                        fontWeight: 400,
                        lineHeight: 1.5,
                        textAlign: 'left'
                    },
                    '#root': {
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'stretch',
                        justifyContent: 'flex-start',
                        flex: 1,
                        '&> .Content': {
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'stretch',
                            justifyContent: 'flex-start'
                        }
                    }
                }
            },
            MuiBreadcrumbs: {
                styleOverrides: {
                    li: {
                        fontSize: 'small'
                    }
                }
            },
            MuiAppBar: {
                styleOverrides: {
                    root: {
                        marginLeft: 'initial !important',
                        width: '100% !important'
                    }
                }
            },
            MuiInputLabel: {
                styleOverrides: {
                    shrink: {...outlinedShrinkLabelOverride}
                }
            },
            MuiFormControlLabel: {
                styleOverrides: {
                    shrink: {...outlinedShrinkLabelOverride}
                }
            },
            MuiFormLabel: {
                styleOverrides: {
                    shrink: {...outlinedShrinkLabelOverride}
                }
            },
            MuiLoadingButton: {
                styleOverrides: {
                    textTransform: 'capitalize'
                }
            },
            MuiDivider: {
                styleOverrides: {
                    textAlignLeft: {
                        color: colors.palette.secondary.dark,
                        fontWeight: 'bold',
                        letterSpacing: 1,
                        fontSize: '1.3em',
                        paddingBottom: baseTheme.spacing(2),
                        '&:before': {
                            width: '1%'
                        },
                        '&:after': {
                            width: '99%'
                        }
                    }
                }
            },
            MuiOutlinedInput: {
                defaultProps: {
                    color: 'secondary'
                },
                styleOverrides: {
                    root: {
                        borderColor: colors.palette.primary[opposizeMode],
                        '&>fieldset': {
                            borderColor: colors.palette.primary[opposizeMode]
                        }
                    },
                    input: {
                        background: theme === 'light' ? colors.palette.common.white : colors.palette.grey[600]
                    }
                }
            },
            MuiChip: {
                styleOverrides: {
                    sizeSmall: {
                        fontSize: '.9em'
                    }
                }
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        textTransform: 'capitalize',
                        paddingLeft: baseTheme.spacing(2),
                        paddingRight: baseTheme.spacing(2),
                        [baseTheme.breakpoints.only('xs')]: {
                            minWidth: baseTheme.spacing(10)
                        }
                    },
                    sizeLarge: {
                        borderRadius: baseTheme.spacing(),
                        fontSize: '1rem'
                    }
                },
                variants: [{
                    props: {variant: 'menu-item'},
                    style: {
                        textTransform: 'uppercase',
                        minWidth: 24,
                        fontSize: typography.typography.fontSize,
                        padding: baseTheme.spacing(1, 2)
                    }
                }]
            },
            MuiSelect: {
                styleOverrides: {
                    nativeInput: {}
                }
            },
            MuiButtonBase: {
                variants: [{
                    props: {variant: 'text', size: 'small'},
                    style: {
                        padding: {
                            xs: `${baseTheme.spacing(1.25, 1)} !important`
                        }
                    }
                }]
            },
            MuiLink: {
                variants: [{
                    props: {component: 'a'},
                    style: {
                        cursor: 'pointer'
                    }
                }]
            },
            MuiDialog: {
                styleOverrides: {
                    paperWidthXs: {
                        [baseTheme.breakpoints.not('xs')]: {
                            width: baseTheme.spacing(45)
                        },
                        [baseTheme.breakpoints.only('xs')]: {
                            width: '100%'
                        }
                    }
                }
            },
            MuiListItemIcon: {
                styleOverrides: {
                    root: {
                        minWidth: baseTheme.spacing(5)
                    }
                }
            },
            MuiFormControl: {
                styleOverrides: {
                    root: {
                        // marginBottom: baseTheme.spacing(3)
                    }
                }
            },
            MuiDialogActions: {
                styleOverrides: {
                    root: {
                        justifyContent: 'space-between'
                    }
                }
            },
            MuiDialogTitle: {
                styleOverrides: {
                    root: {
                        color: colors.palette.primary[opposizeMode]
                    }
                }
            },
            MuiTypography: {
                variants: [{
                    props: {font: 'regular'},
                    style: {
                        fontFamily: variables().fontRegular
                    }
                }, {
                    props: {font: 'primary'},
                    style: {
                        fontFamily: variables().fontPrimary
                    }
                }, {
                    props: {font: 'secondary'},
                    style: {
                        fontFamily: variables().fontSecondary
                    }
                }]
            }
        }
    });
    return components;
};

export default themes;
