export const SET_TOKEN = 'auth/token/set';
export const CLEAR_TOKEN = 'auth/token/clear';
export const REFRESH_TOKEN = 'auth/token/refresh';
export const REFRESHING_TOKEN = 'auth/token/refreshing';
export const REQUEST_LOGIN = 'auth/login/request';
export const LOGIN_ERROR = 'auth/login/error';
export const LOGOUT = 'auth/logout';
export const CLOSE_WELCOME = 'auth/close/welcome';
export const SET_CURRENT_USER = 'auth/user/set';
export default null;
