import Config from 'common/Config';
import dot from 'dot-object';
import {compile} from 'path-to-regexp';

/**
 * Gets route url from the route config
 * @function
 * @param {string} route - path to route config
 * @param {Object<string, string>} data - route parameters that are used in the route pattern
 * @param {boolean} fullPath
 * @returns {string}
 */
export const getRoutePath = (route, data, fullPath) => {
    if (typeof fullPath === 'undefined' || fullPath === null) {
        fullPath = true;
    }
    let result = '';
    route = route.replace(/\./gi, '.children.');
    if (fullPath) {
        const parts = route.split('.');
        const pathBuilder = [];
        parts.forEach((part) => {
            if (part !== 'path') {
                pathBuilder.push(part);
                const dotPath = pathBuilder.join('.') + '.path';
                if (typeof dot.pick(dotPath, Config.get('routes')) !== 'undefined' && dot.pick(dotPath, Config.get('routes')) !== null) {
                    result += '/' + dot.pick(dotPath, Config.get('routes'));
                }
            }
        });
    }
    else {
        result = dot.pick(route.endsWith('path') ? route : route + '.path', Config.get('routes'));
    }
    return resolveRoute(result.replace(/\/\//g, '/').replace(/\/$/g, ''), data);
};

/**
 * Replaces identifiers in route template with parameters
 * (Example: /organizations/:organizationId => /organizations/9117dfb2-1fd0-4746-b3a5-8f258403d5b2)
 * @function
 * @param {string} route - path to route config
 * @param {Object<string, string>} data - route parameters that are used in the route pattern
 * @returns {string}
 */
export const resolveRoute = (route, data) => {
    if (data) {
        if (typeof data !== 'object') {
            console.warn('Path call to ' + route + ' parameters should be json object');
            return null;
        }

        const resolver = compile(route);
        route = resolver(data);
    }

    return route.replace(/\/\//g, '/');
};

/**
 * Gets route config
 * @function
 * @param {string} route - path to route config
 * @param {Object<string, string>} data - route parameters that are used in the route pattern
 * @returns {Object<{
 *  path: string,
 *  element: string,
 *  type: string,
 *  props: Object,
 *  children: Object
 * }>}
 */
export const getRouteObject = (route, data) => {
    if (data) {
        if (typeof data !== 'object') {
            console.warn('Path call to ' + route + ' parameters should be json object');
            return null;
        }
    }
    route = route.replace(/\./gi, '.children.');
    return dot.pick(route.replace(/\.path/, ''), Config.get('routes'));
};

const RouteUtil = {
    resolveRoute,
    getRoutePath,
    getRouteObject
};

export default RouteUtil;
