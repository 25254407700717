export default {
    url: 'https://api.altoida.com',
    routes: {
        auth: {
            config: {
                url: '/configs',
                method: 'GET'
            },
            logout: {
                url: '/auth/logout',
                method: 'POST'
            },
            login: {
                url: '/auth/login',
                method: 'POST'
            },
            refresh: {
                url: '/auth/refresh',
                method: 'POST'
            },
            activate: {
                url: '/auth/activate/:activateToken?',
                method: 'POST'
            },
            reactivate: {
                url: '/auth/reactivate/:activateToken?',
                method: 'POST'
            },
            confirmEmail: {
                url: '/auth/confirm_email/:confirmToken?',
                method: 'POST'
            },
            forgotPassword: {
                url: '/auth/forgot_password',
                method: 'POST'
            },
            resetPassword: {
                url: '/auth/reset_password/:resetToken?',
                method: 'POST'
            },
            requestNewCodeActivateAccount: {
                url: '/auth/generate/activate',
                method: 'POST'
            },
            requestNewCodeConfirmEmail: {
                url: '/auth/generate/activate',
                method: 'POST'
            },
            changePassword: {
                url: '/auth/change-password',
                method: 'POST'
            },
            validateMFA: {
                url: '/auth/mfa/verify',
                method: 'POST'
            },
            enableMFA: {
                url: '/auth/mfa/enable',
                method: 'POST'
            },
            sendMFACode: {
                url: '/auth/mfa/sendCode',
                method: 'POST'
            },
            unlock: {
                url: '/auth/unlock',
                method: 'POST'
            },
            lock: {
                url: '/auth/lock',
                method: 'POST'
            }
        },
        language: {
            public: {
                url: '/translate',
                method: 'GET'
            }
        },
        users: {
            profile: {
                url: '/users/:userId',
                method: 'GET'
            },
            getBudget: {
                url: '/user/budget',
                method: 'POST'
            },
            list: {
                url: '/admin/users',
                method: 'GET',
                scopes: ['list-users']
            },
            create: {
                url: '/admin/users',
                method: 'PUT',
                scopes: ['create-user']
            },
            get: {
                url: '/admin/users/:userId',
                method: 'GET',
                scopes: ['view-user']
            },
            update: {
                url: '/admin/users/:userId',
                method: 'POST',
                scopes: ['update-user']
            },
            delete: {
                url: '/admin/users/:userId',
                method: 'DELETE',
                scopes: ['delete-user']
            },
            validate: {
                url: '/patient/validate-email',
                method: 'POST'
            },
            bulkRecertification: {
                url: '/admin/recertifications/bulk-users',
                method: 'POST'
            }
        },
        permission: {
            list: {
                url: '/admin/permissions',
                method: 'GET',
                scopes: ['list-permissions']
            },
            create: {
                url: '/admin/permissions',
                method: 'PUT',
                scopes: ['create-permission']
            },
            get: {
                url: '/admin/permissions/:permissionId',
                method: 'GET',
                scopes: ['view-permission']
            },
            update: {
                url: '/admin/permissions/:permissionId',
                method: 'POST',
                scopes: ['update-permission']
            },
            delete: {
                url: '/admin/permissions/:permissionId',
                method: 'DELETE',
                scopes: ['delete-permission']
            }
        },
        oauth: {
            list: {
                url: '/admin/oauth/clients',
                method: 'GET',
                scopes: ['oauth-list-clients']
            },
            create: {
                url: '/admin/oauth/clients',
                method: 'PUT',
                scopes: ['oauth-create-client']
            },
            get: {
                url: '/admin/oauth/clients/:oauthId',
                method: 'GET',
                scopes: ['oauth-view-client']
            },
            delete: {
                url: '/admin/oauth/clients/:oauthId/revoke',
                method: 'DELETE',
                scopes: ['oauth-revoke-client']
            }
        },
        role: {
            list: {
                url: '/admin/roles',
                method: 'GET',
                scopes: ['list-roles']
            },
            create: {
                url: '/admin/roles',
                method: 'PUT',
                scopes: ['create-role']
            },
            get: {
                url: '/admin/roles/:roleId',
                method: 'GET',
                scopes: ['view-role']
            },
            update: {
                url: '/admin/roles/:roleId',
                method: 'POST',
                scopes: ['update-role']
            },
            delete: {
                url: '/admin/roles/:roleId',
                method: 'DELETE',
                scopes: ['delete-role']
            }
        },
        module: {
            list: {
                url: '/admin/modules',
                method: 'GET'
            },
            create: {
                url: '/admin/modules',
                method: 'PUT'
            },
            get: {
                url: '/admin/modules/:moduleId',
                method: 'GET'
            },
            update: {
                url: '/admin/modules/:moduleId',
                method: 'POST'
            },
            delete: {
                url: '/admin/modules/:moduleId',
                method: 'DELETE'
            }
        },
        organization: {
            list: {
                url: '/admin/organizations',
                method: 'GET',
                scopes: ['list-organizations']
            },
            create: {
                url: '/admin/organizations',
                method: 'PUT',
                scopes: ['create-organization']
            },
            get: {
                url: '/admin/organizations/:organizationId',
                method: 'GET',
                scopes: ['view-organization']
            },
            update: {
                url: '/admin/organizations/:organizationId',
                method: 'POST',
                scopes: ['update-organization']
            },
            delete: {
                url: '/admin/organizations/:organizationId',
                method: 'DELETE',
                scopes: ['delete-organization']
            },
            transferCredit: {
                url: '/admin/credits/:task/:target_type/:id',
                method: 'POST'
            },
            addModule: {
                url: '/admin/organizations/:organizationId/modules',
                method: 'PUT'
            },
            removeModule: {
                url: '/admin/organizations/:organizationId/modules/:moduleId',
                method: 'DELETE'
            }
        },
        location: {
            list: {
                url: '/admin/locations',
                method: 'GET',
                scopes: ['list-locations']
            },
            create: {
                url: '/admin/locations',
                method: 'PUT',
                scopes: ['create-location']
            },
            get: {
                url: '/admin/locations/:locationId',
                method: 'GET',
                scopes: ['view-location']
            },
            update: {
                url: '/admin/locations/:locationId',
                method: 'POST',
                scopes: ['update-location']
            },
            delete: {
                url: '/admin/locations/:locationId',
                method: 'DELETE',
                scopes: ['delete-location']
            }
        },
        test_results: {
            list: {
                url: '/dashboard/test_results',
                method: 'GET',
                scopes: ['list-test-results', 'isAdministrator']
            },
            retryIntegration: {
                url: '/dashboard/patients/:patientId/results/:resultId/integrate',
                method: 'POST'
            }
        },
        patient: {
            list: {
                url: '/dashboard/patients',
                method: 'GET',
                scopes: ['list-patients']
            },
            create: {
                url: '/dashboard/patients',
                method: 'PUT',
                scopes: ['create-patient']
            },
            get: {
                url: '/dashboard/patients/:patientId',
                method: 'GET',
                scopes: ['view-patient']
            },
            update: {
                url: '/dashboard/patients/:patientId',
                method: 'PATCH',
                scopes: ['update-patient']
            },
            delete: {
                url: '/dashboard/patients/:patientId',
                method: 'DELETE',
                scopes: ['delete-patient']
            },
            release: {
                url: '/dashboard/patients/:patientId/release-user',
                method: 'POST',
                scopes: ['isAdministrator']
            },
            generateLogin: {
                url: '/dashboard/patients/:patientId/generate-program-login',
                method: 'POST',
                scopes: ['create-program-id-login']
            },
            revokeLogin: {
                url: '/dashboard/patients/:patientId/revoke-program-login',
                method: 'POST',
                scopes: ['delete-program-id-login']
            },
            medication: {
                list: {
                    url: '/dashboard/patients/:patientId/medications',
                    method: 'GET',
                    scopes: ['list-patient-medications']
                },
                create: {
                    url: '/dashboard/patients/:patientId/medications/',
                    method: 'PUT',
                    scopes: ['create-patient-medication']
                },
                get: {
                    url: '/dashboard/patients/:patientId/medications/:medicationId',
                    method: 'GET',
                    scopes: ['view-patient-medication']
                },
                update: {
                    url: '/dashboard/patients/:patientId/medications/:medicationId',
                    method: 'POST',
                    scopes: ['update-patient-medication']
                },
                delete: {
                    url: '/dashboard/patients/:patientId/medications/:medicationId',
                    method: 'DELETE',
                    scopes: ['delete-patient-medication']
                }
            },
            allergy: {
                list: {
                    url: '/dashboard/patients/:patientId/allergies',
                    method: 'GET',
                    scopes: ['list-patient-allergies']
                },
                create: {
                    url: '/dashboard/patients/:patientId/allergies',
                    method: 'PUT',
                    scopes: ['create-patient-allergy']
                },
                get: {
                    url: '/dashboard/patients/:patientId/allergies/:allergyId',
                    method: 'GET',
                    scopes: ['view-patient-allergy']
                },
                update: {
                    url: '/dashboard/patients/:patientId/allergies/:allergyId',
                    method: 'POST',
                    scopes: ['update-patient-allergy']
                },
                delete: {
                    url: '/dashboard/patients/:patientId/allergies/:allergyId',
                    method: 'DELETE',
                    scopes: ['delete-patient-allergy']
                }
            },
            medical_history: {
                list: {
                    url: '/dashboard/patients/:patientId/medical-history',
                    method: 'GET',
                    scopes: ['list-patient-medical-histories']
                },
                create: {
                    url: '/dashboard/patients/:patientId/medical-history',
                    method: 'PUT',
                    scopes: ['create-patient-medical-history']
                },
                get: {
                    url: '/dashboard/patients/:patientId/medical-history/:medicalHistoryId',
                    method: 'GET',
                    scopes: ['view-patient-medical-history']
                },
                update: {
                    url: '/dashboard/patients/:patientId/medical-history/:medicalHistoryId',
                    method: 'POST',
                    scopes: ['update-patient-medical-history']
                },
                delete: {
                    url: '/dashboard/patients/:patientId/medical-history/:medicalHistoryId',
                    method: 'DELETE',
                    scopes: ['delete-patient-medical-history']
                }
            },
            documents: {
                categoriesList: {
                    url: '/dashboard/patients/:patientId/documents/categories',
                    method: 'GET',
                    scopes: ['list-patient-documents']
                },
                list: {
                    url: '/dashboard/patients/:patientId/documents',
                    method: 'GET',
                    scopes: ['list-patient-documents']
                },
                get: {
                    url: '/dashboard/patients/:patientId/documents/:documentId',
                    method: 'GET',
                    scopes: ['view-patient-document']
                },
                create: {
                    url: '/dashboard/patients/:patientId/documents/categories/:category_id',
                    method: 'POST',
                    scopes: ['create-patient-document']
                },
                update: {
                    url: '/dashboard/patients/:patientId/documents/documentId',
                    method: 'POST',
                    scopes: ['update-patient-document']
                },
                delete: {
                    url: '/dashboard/patients/:patientId/documents/:documentId',
                    method: 'DELETE',
                    scopes: ['delete-patient-document']
                },
                download: {
                    url: '/dashboard/patients/:patientId/documents/:documentId',
                    method: 'GET',
                    scopes: ['view-patient-document']
                },
                createReport: {
                    url: '/dashboard/patients/:patientId/reports/full-report',
                    method: 'POST',
                    scopes: ['view-patient-document']
                },
                signReport: {
                    url: '/dashboard/patients/:patientId/reports/full-report/:reportId/sign',
                    method: 'POST',
                    scopes: ['view-patient-document']
                }
            },

            testResult: {
                list: {
                    url: '/dashboard/patients/:patientId/test-results',
                    method: 'GET',
                    scopes: ['list-test-results']
                },
                download: {
                    url: '/dashboard/patients/:patientId/reports/:resultId',
                    method: 'GET'
                }
            },

            imagingResult: {
                list: {
                    url: '/dashboard/patients/:patientId/imaging-results',
                    method: 'GET',
                    scopes: ['list-patient-documents']
                },
                get: {
                    url: '/dashboard/patients/:patientId/imaging-results/:imagingId',
                    method: 'GET',
                    scopes: ['view-patient-document']
                },
                create: {
                    url: '/dashboard/patients/:patientId/imaging-results',
                    method: 'PUT',
                    scopes: ['create-patient-document']
                },
                update: {
                    url: '/dashboard/patients/:patientId/imaging-results/:imagingId',
                    method: 'POST',
                    scopes: ['update-patient-document']
                },
                delete: {
                    url: '/dashboard/patients/:patientId/imaging-results/:imagingId',
                    method: 'DELETE',
                    scopes: ['delete-patient-document']
                },
                upload: {
                    url: '/dashboard/patients/:patientId/imaging-results/:imagingId/upload',
                    method: 'POST',
                    scopes: ['update-patient-document']
                },
                download: {
                    url: '/dashboard/patients/:patientId/imaging-results/:imagingId/:imagingType/documents',
                    method: 'GET',
                    scopes: ['view-patient-document']
                }
            },

            labResult: {
                list: {
                    url: '/dashboard/patients/:patientId/lab-results',
                    method: 'GET',
                    scopes: ['list-lab-results']
                },
                get: {
                    url: '/dashboard/patients/:patientId/lab-results/:labResultId',
                    method: 'GET',
                    scopes: ['list-lab-results']
                },
                create: {
                    url: '/dashboard/patients/:patientId/lab-results',
                    method: 'PUT',
                    scopes: ['create-lab-result']
                },
                update: {
                    url: '/dashboard/patients/:patientId/lab-results/:labResultId',
                    method: 'POST',
                    scopes: ['update-lab-result']
                },
                delete: {
                    url: '/dashboard/patients/:patientId/lab-results/:labResultId',
                    method: 'DELETE',
                    scopes: ['delete-lab-result']
                }
            },
            vitals: {
                list: {
                    url: '/dashboard/patients/:patientId/vitals',
                    method: 'GET',
                    scopes: ['list-vitals']
                },
                update: {
                    url: '/dashboard/patients/:patientId/vitals/:vital_id',
                    method: 'POST',
                    scopes: ['update-vital']
                },
                create: {
                    url: '/dashboard/patients/:patientId/vitals',
                    method: 'PUT',
                    scopes: ['create-vital']
                },
                delete: {
                    url: '/dashboard/patients/:patientId/vitals/:vitalId',
                    method: 'DELETE',
                    scopes: ['delete-vital']
                }
            },
            visits: {
                list: {
                    url: '/dashboard/patients/:patientId/visits',
                    method: 'GET',
                    scopes: ['list-visits']
                },
                update: {
                    url: '/dashboard/patients/:patientId/visits/:visitId',
                    method: 'POST',
                    scopes: ['update-visit']
                },
                create: {
                    url: '/dashboard/patients/:patientId/visits',
                    method: 'PUT',
                    scopes: ['create-visit']
                },
                delete: {
                    url: '/dashboard/patients/:patientId/visits/:visitId',
                    method: 'DELETE',
                    scopes: ['delete-visit']
                },
                activities: {
                    list: {
                        url: '/dashboard/patients/:patientId/visits/:visitId/activities',
                        method: 'GET',
                        scopes: ['list-visit-activities']
                    },
                    update: {
                        url: '/dashboard/patients/:patientId/visits/:visitId/activities/:visitActivityId',
                        method: 'POST',
                        scopes: ['update-visit-activity']
                    },
                    create: {
                        url: '/dashboard/patients/:patientId/visits/:visitId/activities',
                        method: 'PUT',
                        scopes: ['create-visit-activity']
                    },
                    delete: {
                        url: '/dashboard/patients/:patientId/visits/:visitId/activities/:visitActivityId',
                        method: 'DELETE',
                        scopes: ['delete-visit-activity']
                    }
                }
            },
            cognitiveStatus: {
                list: {
                    url: '/dashboard/patients/:patientId/cognitive-statuses',
                    method: 'GET',
                    scopes: ['list-cognitive-statuses']
                },
                update: {
                    url: '/dashboard/patients/:patientId/cognitive-statuses/:cognitiveStatusId',
                    method: 'POST',
                    scopes: ['update-cognitive-status']
                },
                create: {
                    url: '/dashboard/patients/:patientId/cognitive-statuses',
                    method: 'PUT',
                    scopes: ['create-cognitive-status']
                },
                delete: {
                    url: '/dashboard/patients/:patientId/cognitive-statuses/:cognitiveStatusId',
                    method: 'DELETE',
                    scopes: ['delete-cognitive-status']
                }
            },
            assessments: {
                list: {
                    url: '/dashboard/patients/:patientId/assessments',
                    method: 'GET',
                    scopes: ['list-patient-questionnaires']
                },
                get: {
                    url: '/dashboard/patients/:patientId/assessments/:patientAssessmentId',
                    method: 'GET',
                    scopes: ['view-patient-questionnaire']
                },
                complete: {
                    url: '/dashboard/patients/:patientId/assessments/:questionnaireId/complete',
                    method: 'POST',
                    scopes: ['update-patient-questionnaire']
                },
                answers: {
                    get: {
                        url: '/dashboard/patients/:patientId/assessments/:questionnaireId/answers',
                        method: 'GET',
                        scopes: ['list-patient-questionnaires']
                    },
                    save: {
                        url: '/dashboard/patients/:patientId/assessments/:patientQuestionnaireId/answers',
                        method: 'PUT',
                        scopes: ['create-patient-questionnaire']
                    },
                    update: {
                        url: '/dashboard/patients/:patientId/assessments/:patientQuestionnaireId/answers/:answerId',
                        method: 'POST',
                        scopes: ['update-patient-questionnaire']
                    }
                }
            },
            history: {
                list: {
                    url: '/admin/patients/:patientId/history',
                    method: 'GET',
                    scopes: ['list-patient-histories']
                }
            }
        },

        hobby: {
            list: {
                url: '/admin/hobbies',
                method: 'GET',
                scopes: ['list-hobbies']
            },
            create: {
                url: '/admin/hobbies',
                method: 'PUT',
                scopes: ['create-hobby']
            },
            update: {
                url: '/admin/hobbies',
                method: 'POST',
                scopes: ['update-hobby']
            },
            delete: {
                url: '/admin/hobbies/:hobbyId',
                method: 'DELETE',
                scopes: ['delete-hobby']
            }
        },
        fitbit: {
            connect: {
                url: '/fitbit/:testSubjectId',
                method: 'GET',
                scopes: ['isPatient']
            },
            disconnect: {
                url: '/services/fitbit/disconnect',
                method: 'POST',
                scopes: ['isPatient']
            }
        }
    }
};
