import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import Config from 'common/Config';
import {AlertOctagonOutline} from 'mdi-material-ui';
import * as PropTypes from 'prop-types';
import React from 'react';

const NotAuthorized = ({theme, classes, ...props}) => {
    return (
        <div className={classes.root}>
            <AlertOctagonOutline style={{fontSize: theme.typography.fontSize * 6}}/>
            <Typography gutterBottom variant={'h4'}>Access to this section is restricted!</Typography>
            <Typography variant={'body2'}>We are sorry but you are not authorized to access this section.</Typography>
            <Typography variant={'body2'}>If you think this is mistake, please contact to your account administrator
                or {Config.get('app.title')} Support.</Typography>
        </div>
    );
};

NotAuthorized.propTypes = {
    theme: PropTypes.object,
    classes: PropTypes.object

};
const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        height: '100%'
    }
});
export default withStyles(styles, {withTheme: true})(NotAuthorized);
