import camelCase from 'lodash/camelCase';
import {upperFirst} from 'lodash/string';
import React from 'react';
import {Outlet} from 'react-router-dom';
import Config from 'src/common/Config';
import Authorized from 'src/views/pages/private/Authorized';

const Components = {};

/**
 * Gets routes config and prepares it for react-router-dom (replaces element path with component wrapped with react lazy)
 * @param {string} parent - path to route config in routes config
 * @param {'public'|'private'} authType
 * @returns {Object}
 */
const renderRoutes = (parent, authType) => {
    const route = Config.get(`routes.${parent}`);
    if (route) {
        const {
            type,
            path,
            element,
            children,
            props: routeProps,
            scopes: componentScopes,
            ...rest
        } = route;

        if (type !== 'page') {
            return;
        }
        const newRouteConfig = {
            type,
            ...routeProps,
            ...rest
        };

        if (path) {
            let newPath = path;
            if (newPath.charAt(0) === '/' && newPath !== '/') {
                newPath = newPath.substring(1);
            }
            Object.assign(newRouteConfig, {path: newPath});
        }

        if (children && Object.keys(children).length) {
            const newChildren = Object.keys(children).map((r) => {
                return renderRoutes(`${parent}.children.${r}`, authType);
            }).filter(Boolean);

            Object.assign(newRouteConfig, {children: newChildren});
        }
        let Component;
        const componentProps = {};
        if (element) {
            const elementPath = `views/pages/${authType}/${element}`;
            Components[upperFirst(camelCase(elementPath))] = React.lazy(() => import(`views/pages/${authType}/${element}`));
            Component = Components[upperFirst(camelCase(elementPath))];
        }
        Object.assign(componentProps, {...routeProps});

        if (Component) {
            if (componentScopes?.length > 0) {
                Component =
                    <Authorized componentScopes={componentScopes} {...componentProps}>
                        <Component {...componentProps} {...rest}/>
                    </Authorized>;
            }
            else {
                Component = <Component {...componentProps} {...rest}/>;
            }
        }
        else {
            Component = <><Outlet context={{...componentProps, ...rest, blank: true}}/></>;
        }

        Object.assign(newRouteConfig, {
            element: Component
        });
        return newRouteConfig;
    }
};

/**
 * Prepares route config for react-router-dom
 * @function
 * @returns {Object[]}
 */
export const useCurrentRoutes = () => {
    const publicRoutes = renderRoutes('public', 'public');
    const privateRoutes = renderRoutes('private', 'private');
    return [publicRoutes, privateRoutes];
};
