import {Dialog} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import isArray from 'lodash/isArray';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {connect, useSelector} from 'react-redux';
import UISelector from 'store/ui/UI.selectors';
import LogoSpinner from 'views/components/LogoSpinner';

function Loading(props) {
    let message = useSelector(UISelector.getLoading);

    const {t} = useTranslation(['common']);
    const {nonblocking, theme, classes, container} = props;
    const [anchorEl, setAnchorEl] = useState();
    useEffect(() => {
        if (!container) {
            setAnchorEl(document.body);
        }
        else {
            if (container.current) {
                setAnchorEl(container.current);
            }
        }
    }, []);

    if (message && isArray(message)) {
        let [text, params] = message;
        params = params && Object.keys(params).map(p => ({[p]: t(params[p])})).reduce((a, b) => ({...a, ...b}), {});
        message = t(text, {...params});
    }

    return (
        <Dialog
            classes={{
                paper: classes.paper
            }}
            open={Boolean(message)}
            hideBackdrop={nonblocking}
            disableEscapeKeyDown
            keepMounted={false}
            container={anchorEl}
        >
            {message ? (
                <div className={classes.messageContainer}>
                    <div
                        style={{
                            position: 'relative',
                            padding: theme.spacing(0, 1, 0, 2)
                        }}
                    >
                        <LogoSpinner
                            classes={{
                                root: classes.logoSpinner
                            }}
                        />
                    </div>
                    <div
                        className={classes.message}
                        dangerouslySetInnerHTML={{__html: `${t(message)}`}}
                    />
                </div>
            ) : (
                <span></span>
            )}
        </Dialog>
    );
}

const styles = (theme) => ({
    root: {},
    paper: {
        borderRadius: 50,
        overflow: 'hidden',
        padding: theme.spacing(0, 1)
    },
    messageContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        padding: theme.spacing(2)
    },
    message: {
        paddingLeft: theme.spacing()
    },
    logoSpinner: {
        position: 'relative',
        left: theme.spacing(0.5),
        width: theme.spacing(8),
        height: theme.spacing(8)
    }
});

Loading.defaultProps = {
    nonblocking: false,
    type: 'circle'
};

Loading.propTypes = {
    t: PropTypes.func,
    nonblocking: PropTypes.bool,
    type: PropTypes.string,
    theme: PropTypes.object,
    classes: PropTypes.object,
    container: PropTypes.any
};

export default withStyles(styles, {withTheme: true})(Loading);
