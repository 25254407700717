import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {Alert as AlertTriangle, AlertOctagon, CheckCircle, Lightbulb} from 'mdi-material-ui';
import memoizeOne from 'memoize-one';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {closeAlert} from 'store/ui/UI.slice';
import UISelector from 'store/ui/UI.selectors';

const Alert = (props) => {
    const {
        classes,
        theme,
        open,
        onOpen,
        onConfirm,
        onCancel,
        onClose,
        html,
        showCancelButton,
        showConfirmButton,
        confirmButtonText,
        confirmButtonColor,
        cancelButtonText,
        buttonVariant,
        cancelButtonVariant,
        confirmButtonVariant,
        title,
        text,
        type,
        size,
        closeAlert,
        actions
    } = props;
    const {t} = useTranslation('common');
    const getIcon = memoizeOne(
        (type) => {
            switch (type) {
                case 'success':
                    return CheckCircle;
                case 'error':
                    return AlertOctagon;
                case 'info':
                    return Lightbulb;
                case 'warning':
                    return AlertTriangle;
                default:
                    return null;
            }
        },
        [type]
    );
    const AlertIcon = getIcon(type);
    return (
        <Dialog
            classes={{
                root: classes.root,
                paper: classes.paper
            }}
            open={open}
            hideBackdrop={false}
            disableEscapeKeyDown
            // disableBackdropClick
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    closeAlert();
                    onClose && onClose();
                }
            }}
            keepMounted={false}
            scroll={'body'}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth={size}
            TransitionProps={{
                onEntered: onOpen
            }}
        >
            {title && (!type || type === 'default') && (
                <DialogTitle className={classes.title} id="alert-dialog-title">
                    {title}
                </DialogTitle>
            )}
            <DialogContent
                id="alert-dialog-description"
                className={classes.messageContainer}

            >
                {type && type !== 'default' && (
                    <div
                        style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: '100%',
                            justifyContent: 'center'
                        }}
                    >

                        <div
                            style={{
                                borderRadius: '50%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: theme.spacing(1),
                                border: `${theme.spacing(.75)} solid ${theme.palette[type][theme.palette.mode]}`,
                                margin: theme.spacing(0, 0, 3, 0)
                            }}
                        >
                            <AlertIcon
                                className={classes.messageIcon}
                                style={{color: theme.palette[type][theme.palette.mode]}}
                            />
                        </div>
                        {title && type !== 'default' && (
                            <Typography
                                className={classes.title}
                                id="alert-dialog-title"
                                variant={'h5'}
                                align={'center'}
                            >
                                {t(title)}
                            </Typography>
                        )}
                        <Typography variant={'body1'} align={'center'} className={classes.messageDiv}>
                            {html ? (
                                <div
                                    className={classes.message}
                                    dangerouslySetInnerHTML={{__html: `${t(text)}`}}
                                />
                            ) : (
                                <span className={classes.message}>{t(text)}</span>
                            )}
                        </Typography>
                    </div>
                )}
            </DialogContent>

            <DialogActions>
                {showCancelButton || showConfirmButton || onCancel || onConfirm ? (
                    <div className={classes.actions}>
                        {(showCancelButton || onCancel) && (
                            <Button
                                onClick={() => {
                                    closeAlert();
                                    onCancel && onCancel();
                                }}
                                className={classes.cancelButton}
                                variant={cancelButtonVariant || buttonVariant || 'outlined'}
                            >
                                {t(cancelButtonText)}
                            </Button>
                        )}
                        {(showConfirmButton || onConfirm) && (
                            <Button
                                onClick={() => {
                                    closeAlert();
                                    onConfirm && onConfirm();
                                }}
                                color={confirmButtonColor || 'primary'}
                                variant={confirmButtonVariant || buttonVariant || 'contained'}
                                className={classes.confirmButton}
                            >
                                {t(confirmButtonText)}
                            </Button>
                        )}
                    </div>
                ) : (
                    <div className={classes.actions}>
                        <div>&nbsp;</div>
                        <Button
                            onClick={closeAlert}
                            color={confirmButtonColor || 'primary'}
                            variant={confirmButtonVariant || buttonVariant}
                        >
                            {t('common:ok')}
                        </Button>
                    </div>
                )}
            </DialogActions>
        </Dialog>
    );
};
Alert.propTypes = {
    ...Dialog.propTypes,
    theme: PropTypes.object,
    classes: PropTypes.object,
    /** Event handler for confirm button click */
    onConfirm: PropTypes.func,
    /** Event handler for cancel button click */
    onCancel: PropTypes.func,
    /** Event handler when alert message close */
    onClose: PropTypes.func,
    /** Enable html content in message body. */
    html: PropTypes.bool,
    /** Display cancel button */
    showCancelButton: PropTypes.bool,
    /** Display Confirmation button */
    showConfirmButton: PropTypes.bool,
    /** Text for confirmation button */
    confirmButtonText: PropTypes.string,
    confirmButtonVariant: PropTypes.oneOf(['contained', 'outlined', 'text', 'confirm']),
    /** Color of confirmation button */
    confirmButtonColor: PropTypes.string,
    /** Text for cancel button */
    cancelButtonText: PropTypes.string,
    buttonVariant: PropTypes.oneOf(['contained', 'outlined', 'text', 'cancel']),
    /** Title of alert message */
    title: PropTypes.string,
    /** Text content */
    text: PropTypes.string,
    /** Type of alert message */
    type: PropTypes.oneOf(['warning', 'error', 'success', 'info', 'default'])
};

Alert.defaultProps = {
    type: 'default',
    confirmButtonText: 'common:confirm',
    cancelButtonText: 'common:cancel',
    confirmButtonVariant: 'contained',
    cancelButtonVariant: 'outlined',
    size: 'sm'
};

const styles = (theme) => ({
    root: {},
    paper: {padding: theme.spacing(3)},
    messageContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        padding: `${theme.spacing(1)} !important`
    },
    title: {
        padding: theme.spacing(),
        color: theme.palette.primary.main,
        fontWeight: 700
    },
    message: {
        padding: theme.spacing(),
        width: '100%'
    },
    messageDiv: {
        flex: 1,
        color: theme.palette.primary[theme.palette.mode]
    },
    messageIcon: {
        fontSize: theme.spacing(12)
    },
    actions: {
        flex: 1,
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    cancelButton: {},
    confirmButton: {}
});

const mapStateToProps = (state, ownProps) => {
    const alertData = UISelector.getAlert(state);
    return {
        ...alertData,
        open: Boolean((alertData && alertData.text) || ownProps.open)
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        closeAlert: () => dispatch(closeAlert())
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, {withTheme: true})(Alert));
