import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {loading} from 'store/ui/UI.slice';

const Preloader = ({loading}) => {

    useEffect(() => {
        loading({
            type: 'preloading',
            message: 'common:preloading'
        });
        return function () {
            loading('preloading');
        };
    }, []);
    return null;
};

Preloader.propTypes = {
    loading: PropTypes.func
};

export default connect(null, (dispatch) => ({
    loading: (props) => dispatch(loading(props))
}))(Preloader);
