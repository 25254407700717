import {replaceStraight} from '@lagunovsky/redux-react-router';
import Config from 'common/Config';
import Messages from 'common/constants/Messages';
import {parseParamsFromLocation} from 'common/helpers/location';
import RouteUtil from 'common/RouteUtil';
import SessionStorage from 'common/SessionStorage';
import SubmissionError from 'core/Error/SubmissionError';
import SystemError from 'core/Error/SystemError';
import {Headers} from 'cross-fetch';
import kebabCase from 'lodash/kebabCase';
import moment from 'moment';
import store from 'store';
import {clearToken, refreshingToken, setToken} from 'store/auth/AuthActions';

import AuthSelectors from 'store/auth/AuthSelectors';

/**
 * Class used send requests to the API
 * @constructor
 */
const API = function () {
    const _self = this;
    /**
     * Timer property to track session timeout. This property is set to a setTimeout instance after oauth token is retrieved from API server.
     * Timer will automatically start until it hits expires_in time period. Right before token expires system will send a request to API server
     * with refresh_token to retrieve a new token.
     */
    this.token_timer = null;
    /**
     * Scopes of the current user. Used to determine whether the user has the necessary access to various parts of the application.
     * @type {string[]}
     */
    this.scopes = [];

    return _self.initializeScopes();
};

/**
 * @typedef {Object<{
 *  access_token: string,
 *  token_type: string,
 *  challenge: string,
 *  isRefreshing: boolean,
 *  status: string,
 *  userId :string
 * }>} Token
 */

/**
 * Refreshes the user's token. Used when the token has expired
 * @returns {Promise<Token>}
 */
API.prototype.refreshToken = async function () {
    try {
        const params = {};
        store.dispatch(refreshingToken(true));
        const refresh_token = AuthSelectors.getRefreshToken(store.getState());
        if (!refresh_token) {
            throw new Error('Invalid refresh token');
        }
        const endpoint = this.resolveRoute('auth', 'refresh');
        const token = await this.request(
            endpoint.url,
            params,
            endpoint.method,
            true,
            {
                Authorization: refresh_token
            }
        );

        if (token.code) {
            throw token;
        }

        await this.saveToken(token);
        return token;
    }
    catch (e) {
        store.dispatch(clearToken());
        store.dispatch(refreshingToken(false));
        store.dispatch(replaceStraight(RouteUtil.getRoutePath('private.logout')));
    }
};

/**
 * Gets current token
 * @returns {Token}
 */
API.prototype.getToken = function () {
    let token = AuthSelectors.getAuth(store.getState());
    if (!token || !token.access_token) {
        if (SessionStorage.has('token') && SessionStorage.get('token') !== '') {
            token = JSON.parse(SessionStorage.get('token'));
        }
    }
    return token;
};

/**
 * Saves token in storage
 * @param {Token} token
 */
API.prototype.saveToken = function (token) {
    store.dispatch(setToken(token));
};

/**
 * Checks if the application has a token
 * @returns {boolean}
 */
API.prototype.hasToken = function () {
    return Boolean(AuthSelectors.getAccessToken(store.getState()) || SessionStorage.get('token'));
};

/**
 * Checks if the application has special token without refresh token
 * @returns {boolean}
 */
API.prototype.isSpecialToken = function () {
    const token = this.getToken();
    return token && !token.refresh_token;
};

/**
 * Checks if the token has expired
 * @returns {boolean}
 */
API.prototype.isTokenExpired = function () {
    const token = this.getToken();
    return !token || moment.unix(token.expires).utc().isBefore(moment().utc());
};

/**
 * Returns a promise that will be resolved after the token is refreshed
 * @returns {Promise<unknown>}
 */
API.prototype.ensureTokenIsNotRefreshing = function () {
    return new Promise((resolve, reject) => {
        (function waitForNewToken() {
            if (!AuthSelectors.getIsTokenRefreshing(store.getState())) {
                return resolve();
            }
            setTimeout(waitForNewToken, 500);
        })();
    });
};

/**
 * Gets scopes from session storage
 */
API.prototype.initializeScopes = function () {
    if (SessionStorage.has('token')) {
        try {
            const token = JSON.parse(SessionStorage.get('token'));

            this.scopes = token.scopes;
        }
        catch (e) {
            console.error('Unable to parse user token');
        }
    }
};

/**
 * Checks if the user has enough permission to send the request
 * @param {string} service - the name of the service
 * @param {string} endpoint - current service endpoint name
 * @param {Object<string,string>} params - route params that are used in the route pattern
 * (Example: /organizations/:organizationId)
 */
API.prototype.validate = function (service, endpoint, params) {
    const scopes = Config.get(`api.routes.${service}.${endpoint}.scopes`, {}) || [];

    if (scopes.length && this.scopes.length) {
        const scopesIntersection = scopes.filter(scope => this.scopes.includes(scope));

        if (!scopesIntersection.length) {
            const endpointPath = Config.get(
                `api.routes.${service}.${endpoint}.url`,
                false
            );
            const path = RouteUtil.resolveRoute(endpointPath, params);

            console.error(`Not enough permissions to send the request: ${path}`);
            throw new TypeError('You don\'t have enough permissions to send the request');
        }
    }
};

/**
 * Gets service parameters used to send the request
 * @param {string} service - the name of the service
 * @param {string} endpoint - current service endpoint name
 * @param {Object<string,string>} params - route params that are used in the route pattern
 * @returns {{headers: Object<string, string>, method: string, url: string}}
 */
API.prototype.resolveRoute = function (service, endpoint, params) {
    if (!service || !endpoint) {
        throw new TypeError('service or endpoint parameters missing in api configuration');
    }

    this.validate(service, endpoint, params);

    const endpointPath = Config.get(`api.routes.${service}.${endpoint}.url`, false);
    const paramsFromLocation = parseParamsFromLocation(window.location.pathname, endpointPath);
    if (!endpointPath) {
        throw new TypeError('Invalid API Configuration');
    }
    let url = Config.get('api.url', '');
    const method = Config.get(
        `api.routes.${service}.${endpoint}.method`,
        'GET'
    );
    const version = Config.get('api.version', false);
    const headers = Config.get(`api.routes.${service}.${endpoint}.headers`, {});
    const path = RouteUtil.resolveRoute(endpointPath, {...paramsFromLocation, ...params});
    url = `${url}${version ? '/' + version : ''}${path}`;
    return {url, method, headers};
};

/**
 * A high-level wrapper around the httprequest method, which is used to send a request to an API. Refreshes the token if
 * necessary. Adds authorization headers
 * @param {string} endpoint - endpoint url
 * @param {Object<string, string>} params - route params that are used in the route pattern
 * @param {string} method - request method
 * @param {boolean} isPublic - request type
 * @param {Object<string, string>} additionalHeaders
 * @param {boolean} isDownloadable - a flag to identify the request that is downloading the data.
 * @returns {Promise<*>}
 */
API.prototype.request = async function (
    endpoint,
    params,
    method,
    isPublic,
    additionalHeaders,
    isDownloadable = false
) {
    if (!additionalHeaders) {
        additionalHeaders = {};
    }
    const _self = this;

    if (!this.scopes) {
        this.initializeScopes();
    }

    if (!isPublic) {
        await this.ensureTokenIsNotRefreshing();
        if (this.isTokenExpired()) {
            await this.refreshToken();
        }

        if (
            this.hasToken() &&
            !Object.keys(additionalHeaders).includes('Authorization')
        ) {
            const token = this.getToken();
            if (typeof token !== 'undefined' && token && token.access_token) {
                Object.assign(additionalHeaders, {
                    authorization: `${token.token_type || 'Bearer'} ${token.access_token}`
                });
            }
        }
    }

    if (!endpoint.startsWith('http')) {
        endpoint = `${Config.get('api.url')}${endpoint}`;
    }

    const response = await _self.httprequest(
        endpoint,
        params,
        method,
        isPublic,
        additionalHeaders,
        isDownloadable
    );
    // fatal error, user logged in with same credentials on same device. This session is abandoned.
    if (response?.error && response?.error === 'invalid-token') {
        store.dispatch(refreshingToken(false));
        store.dispatch(clearToken());
        store.dispatch(replaceStraight(RouteUtil.getRoutePath('private.logout')));
    }
    if (response.status && response.status === 404) {
        throw new SystemError({
            error: 'Not Found',
            message: response.errorDescription || Messages.NOT_FOUND
        });
    }

    if (response.status && response.status === 500) {
        throw new SystemError({
            error: 'internal-server-error',
            message: response.errorDescription || Messages.SERVER_UNAVAILABLE
        });
    }
    if (response.status && response.status === 400 && response.error === 'validation-error') {
        throw response;
    }
    // for any error we handle them manually in their respective service calls.
    return response;
};

/**
 * Wrapper over fetch, used to send the request. Adds necessary headers
 * @param {string} url - endpoint url
 * @param {Object<string, string>} params - route params that are used in the route pattern
 * @param {string} method - request method
 * @param {boolean} isPublic - request type
 * @param {Object<string, string>} additionalHeaders
 * @param {boolean} isDownloadable - a flag to identify the request that is downloading the data.
 * @returns {Promise<*>}
 */
API.prototype.httprequest = async function (
    url,
    params,
    method,
    isPublic,
    additionalHeaders,
    isDownloadable = false
) {
    const _self = this;

    if (!additionalHeaders) {
        additionalHeaders = {};
    }

    if (!params) {
        params = {};
    }
    const requestSlug = kebabCase(`${method}_${url}`);

    /* istanbul ignore next */
    if (typeof _self[requestSlug] !== 'undefined' && method !== 'POST') {
        _self[requestSlug].abort();
    }

    const requestHeaders = {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...additionalHeaders
    };
    if (params.document) {
        delete requestHeaders['Content-Type'];
    }

    const headers = new Headers();
    Object.entries(requestHeaders).map(([key, value], index) => {
        headers.append(key, value);
    });

    const options = {
        method: method || 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
        headers
    };

    if (Object.keys(params).length > 0) {
        if (!method || method === 'GET') {
            const query = Object.keys(params)
                .map((key) => {
                    const val = typeof params[key] === 'object' ? JSON.stringify(params[key]) : params[key];
                    return encodeURIComponent(key) + '=' + encodeURIComponent(val);
                })
                .join('&')
                .replace(/%20/g, '+');
            url = `${url}?${query}`;
        }
        else {
            if (params.document) {
                const data = new FormData();
                data.append('document', params.document[0]);
                data.append('file_name', params.file_name);
                Object.assign(options, {body: data});
            }
            else {
                Object.assign(options, {body: JSON.stringify(params)});
            }
        }
    }
    if (Array.isArray(params) && params.length === 0) {
        Object.assign(options, {body: JSON.stringify(params)});
    }

    try {
        const response = await fetch(url, options);

        if (response.status && response.status === 404) {
            throw new SystemError({
                error: 'Not Found',
                status: 404,
                message: response.errorDescription || Messages.NOT_FOUND
            });
        }

        if (response.status && response.status === 500) {
            const result = await response.json();
            throw {
                status: response.status,
                error: 'internal-system-error',
                message: Messages.SYSTEM_ERROR
            };
        }

        if (response.status >= 400) {
            const result = await response.json();
            throw {
                status: response.status,
                ...result
            };
        }

        if (isDownloadable) {
            const blob = await response.blob();
            return blob;
        }

        const result = await response.json();
        delete _self[requestSlug];
        return result;

    }
    catch (e) {
        if (e instanceof TypeError) {
            throw e;
        }
        return e;
    }
};
export default new API();
