/**
 * UserService definitions
 */

import {createAsyncThunk} from '@reduxjs/toolkit';
import API from 'common/API';
import RouteUtil from 'common/RouteUtil';
import BaseService from 'store/BaseService';
import store from 'store/index';
import _ from 'lodash';

// AuthSelectors are not used here to avoid circular dependencies
const getUserId = (state) => _.get(state, 'auth.userId');
const getRefreshToken = (state) => _.get(state, 'auth.refresh_token', null);

class UserService extends BaseService {

    constructor(props) {
        super({
            model: 'user',
            service: 'users',
            isPublic: false
        });

        this._bulkRecertify = this._bulkRecertify.bind(this);
        this._validateEnableMFA = this._validateEnableMFA.bind(this);
        this._getMe = this._getMe.bind(this);

        this.getMe = createAsyncThunk(`${this.model}/getById`, this._getMe);
        this.bulkRecertify = createAsyncThunk(`${this.model}/bulkCertify`, this._bulkRecertify);
        this.validateEnableMFA = createAsyncThunk(`${this.model}/validateAndEnableMFA`, this._validateEnableMFA);
    }

    _getMe = async ({params, additionalHeaders}) => {
        await this._validate();
        const userId = getUserId(store.getState());
        if (!userId) {
            // something is wrong. better to close session
            window.location.href = RouteUtil.getRoutePath('private.logout');
        }

        const r = getRefreshToken(store.getState());

        if (!r) {
            additionalHeaders = {
                ...additionalHeaders,
                'x-user-id': userId
            };
        }

        const {url, method} = API.resolveRoute(this.service, 'profile', {userId: 'me'});
        const response = await API.request(url, params, method, false, additionalHeaders);

        if (response && response.id) {
            return response;
        }
        throw response;
    };
    _bulkRecertify = async (data = []) => {
        await this._validate();
        if (!data || data.length === 0) {
            return;
        }
        const {url, method} = API.resolveRoute(this.service, 'bulkRecertification');
        const response = await API.request(url, data, method, false);
        if (response && !response.error) {
            return response;
        }

        throw response;
    };
    _validateEnableMFA = async (data = []) => {
        await this._validate();
        if (!data || data.length === 0) {
            return;
        }
        const {url, method} = API.resolveRoute(this.service, 'enableMFA');
        const response = await API.request(url, data, method, false);
        if (response && !response.error) {
            return response;
        }

        throw response;
    };
}

export default new UserService();
