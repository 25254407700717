import DialogFormContextProvider, {DialogFormContext} from 'common/DialogFormContext';
import SessionStorage from 'common/SessionStorage';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import AuthService from 'store/auth/AuthService';
import {loading as showLoading} from 'store/ui/UI.slice';
import Alert from 'views/components/Alert';
import LazyDialog from 'views/components/LazyDialog';
import Loading from 'views/components/Loading';
import Notification from 'views/components/Notification';
import Preloader from 'views/components/Preloader';
import Base from 'views/pages/Base';

const Main = (props) => {
    const [ready, setReady] = useState(false);
    const dispatch = useDispatch();
    const loading = useCallback((data) => dispatch(showLoading(data)), [dispatch]);

    const checkToken = useCallback(async () => {
        loading({
            type: 'application-loading',
            message: 'common:preloading'
        });

        if (SessionStorage.has('token')) {
            const token = JSON.parse(SessionStorage.get('token'));
            await AuthService.saveToken(token);
        }
        setReady(true);
    }, []);

    useEffect(() => {
        try {
            checkToken().catch(console.error);
        }
        finally {
            setTimeout(() => {
                setReady(true);
                loading('application-loading');
            }, 1500);
        }
    }, []);

    return (
        <React.Fragment>
            <React.Suspense fallback={<Preloader />}>
                {ready && <Base />}
            </React.Suspense>
            <Alert />
            <Loading />
            <Notification />
            <DialogFormContextProvider>
                <LazyDialog />
            </DialogFormContextProvider>
            {/* <PreferencesMenu onClickToggleTheme={props.toggleTheme}/> */}
        </React.Fragment>
    );
};

Main.defaultProps = {};

Main.propTypes = {};

export default Main;
