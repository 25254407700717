import {ReduxRouter} from '@lagunovsky/redux-react-router';
import {CssBaseline, StyledEngineProvider, ThemeProvider} from '@mui/material';
import SessionStorage from 'common/SessionStorage';
import i18next from 'core/i18next';
import find from 'lodash/find';
import get from 'lodash/get';
import moment from 'moment/moment';
import {getLangSuccess} from 'store/config/Config.slice';
import API from 'common/API';
import Config from 'common/Config';
import themes from 'common/themes';
import {SnackbarProvider} from 'notistack';
import PropTypes from 'prop-types';
import React, {Suspense, useEffect, useState} from 'react';
import {connect, Provider} from 'react-redux';
import store, {history} from 'store/index';
import UISelectors from 'store/ui/UI.selectors';
import BreadcrumbProvider from 'common/BreadcrumbContext';
import Preloader from 'views/components/Preloader';
import Main from 'views/pages/Main';

const loadLanguages = async () => {
    let languages;
    try {
        const endpoint = API.resolveRoute('language', 'public');
        languages = await API.httprequest(endpoint.url, {}, endpoint.method, true);
    }
    catch (e) {
        process.env.REACT_APP_ENV !== 'production' && console.log(e);
        languages = [{
            'name': 'English',
            'slug': 'english',
            'original': 'English',
            'shortname': 'en',
            'country': 'us',
            'language': 'en',
            'locale': 'en-us'
        }];
    }
    finally {
        store.dispatch(getLangSuccess(languages.map(lng => ({...lng, id: lng.slug}))));
    }
    return languages;
};

function App(props) {
    return (
        <Provider store={store}>
            <ReduxRouter enableTimeTravelling={true} history={history}>
                <SnackbarProvider maxSnack={3} preventDuplicate>
                    <BreadcrumbProvider>
                        <Suspense fallback={<Preloader />}>
                            <ConnectedSubmodules />
                        </Suspense>
                    </BreadcrumbProvider>
                </SnackbarProvider>
            </ReduxRouter>
        </Provider>
    );
}

const SubModules = (props) => {
    const [ready, setReady] = useState(false);
    useEffect(() => {
        loadLanguages()
            .then(langs => {
                const lang = get(find(langs, {shortname: SessionStorage.get('i18nextLng') || i18next.languages[0]}), 'shortname', i18next.options.fallbackLng[0]);
                moment.locale(lang);
                return i18next.changeLanguage(lang);
            })
            .catch(e => {
                process.env.REACT_APP_ENV !== 'production' && console.log(e);
                throw e;
            });
        setReady(true);
    }, []);

    document.title = Config.get('app.title');
    document.body.classList.remove('theme-light');
    document.body.classList.remove('theme-dark');
    document.body.classList.add(`theme-${props.theme}`);

    return ready && (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(props.theme)}>
                <CssBaseline />
                {/* <React.Suspense fallback={<Preloader/>}> */}
                <Main {...props} />
                {/* </React.Suspense> */}
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

SubModules.propTypes = {
    theme: PropTypes.string
};
SubModules.defaultProps = {
    theme: 'light'
};

const ConnectedSubmodules = connect((state) => ({
    theme: UISelectors.getTheme(state)
}))(SubModules);

export default App;
