export default {
    ERROR: 'Error ',
    LOGIN_ERROR: 'Login Error!',
    SYSTEM_ERROR: 'System error occurred. Please try again.',
    SERVER_UNAVAILABLE: 'Server unavailable or can not connect. Please try again.',
    SUCCESS: 'Successful',
    NO_USERNAME: 'Please enter your username.',
    NO_PASSWORD: 'Please enter your password.',
    NO_PASSWORD_CONFIRMATION: 'Please enter your password confirmation. ',
    NO_USERNAME_PASSWORD: 'Please enter your username and password.',
    NO_RESET_PASSWORD_CODE: 'Please use the link or code from the email you received to reset your password.',
    NO_ACTIVATION_CODE: 'Please use the link or code from the email you received to activate your account.',
    PASSWORD_CONFIRMATION_ERROR: 'Password and password confirmation fields do not match. Please try again.',
    NOT_AUTHORIZED: 'You are not authorized to perform this action.',
    COMPANY_NOT_FOUND: 'Company domain you are looking for was not found.',
    NOT_FOUND: 'Resource you requested can not be found'
};
