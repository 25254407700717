import {createDraftSafeSelector} from '@reduxjs/toolkit';
import _ from 'lodash';

const emptyArray = [];
const emptyObject = {};

const getRootState = state => state.ui || emptyObject;

class UISelectorFactory {
    getSidebarId = (state, id) => id || null;
    getUI = getRootState;
    getTheme = createDraftSafeSelector(
        [this.getUI],
        ui => _.get(ui, 'theme', 'light')
    );
    getSidebars = createDraftSafeSelector(
        [this.getUI],
        ui => _.get(ui, 'sidebars', emptyObject)
    );
    getSidebarById = createDraftSafeSelector(
        [this.getUI, this.getSidebarId],
        (ui, sidebarId) => ui && sidebarId && _.get(ui, `sidebars[${sidebarId}]`, emptyObject)
    );
    getUIMainSidebar = createDraftSafeSelector(
        [this.getUI],
        ui => _.get(ui, 'sidebars.main', null)
    );
    getUISecondarySidebar = createDraftSafeSelector(
        [this.getUI],
        ui => _.get(ui, 'sidebars.secondary', null)
    );
    getUIRightSidebar = createDraftSafeSelector(
        [this.getUI],
        ui => _.get(ui, 'sidebars.right', null)
    );
    getHasNotification = createDraftSafeSelector(
        [this.getUI],
        ui => Boolean(Object.keys(_.get(ui, 'notifications', emptyObject)).length)
    );
    getNotifications = createDraftSafeSelector(
        [this.getUI],
        ui => _.get(ui, 'notifications', emptyObject)
    );
    getWizard = createDraftSafeSelector(
        [this.getUI],
        ui => _.get(ui, 'wizard', emptyObject)
    );
    getWizardStep = createDraftSafeSelector(
        [this.getUI],
        ui => _.get(ui, 'wizard.currentStep', 0)
    );
    getWizardData = createDraftSafeSelector(
        [this.getUI],
        ui => _.get(ui, 'wizard.data', 0)
    );
    getHasAlert = createDraftSafeSelector(
        [this.getUI],
        ui => Boolean(_.get(ui, 'alert', false))
    );
    getAlert = createDraftSafeSelector(
        [this.getUI],
        ui => _.get(ui, 'alert', null)
    );
    getLazyDialog = createDraftSafeSelector(
        [this.getUI],
        ui => _.get(ui, 'dialog', null)
    );
    getLoading = createDraftSafeSelector(
        [this.getUI],
        ui => _.chain(ui).get('loading').last().get('message', null).value()
    );
}

export default new UISelectorFactory();
