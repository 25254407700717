import RouteUtil from 'common/RouteUtil';
import SessionStorage from 'common/SessionStorage';

import {
    CLEAR_TOKEN,
    CLOSE_WELCOME,
    LOGIN_ERROR,
    LOGOUT,
    REFRESH_TOKEN,
    REFRESHING_TOKEN,
    REQUEST_LOGIN,
    SET_CURRENT_USER,
    SET_TOKEN
} from 'store/auth/AuthActionTypes';
import UserSlice, {clearUser} from 'store/user/User.slice';

export function requestLogin() {
    return {
        type: REQUEST_LOGIN
    };
}

export function closeWelcomeMessage() {
    return {
        type: CLOSE_WELCOME
    };
}

export function setCurrentUser(userId) {
    return dispatch => {
        dispatch({
            type: SET_CURRENT_USER,
            userId
        });
    };
}

export function setToken(data) {

    const {welcome, ...token} = data;

    SessionStorage.set('token', JSON.stringify(token));

    return dispatch => {
        dispatch({
            type: SET_TOKEN,
            token: data
        });
    };
}

export function clearToken() {
    SessionStorage.remove('token');
    return {
        type: CLEAR_TOKEN
    };
}

export function refreshToken() {
    return {
        type: REFRESH_TOKEN
    };
}

export function refreshingToken(isRefreshing) {
    return {
        type: REFRESHING_TOKEN,
        isRefreshing
    };
}

export function logout() {

    SessionStorage.clear();

    return (dispatch) => {
        dispatch({
            type: LOGOUT
        });
    };
}

export function loginError(e) {
    return (dispatch) => {

        dispatch({
            type: LOGIN_ERROR
        });

        dispatch(clearUser());
    };
}

export default null;
