import store from 'store/index';

import {showAlert} from 'store/ui/UI.slice';

/**
 * A throwable error that is used to show error message for the user
 * @class
 */
class UserError {
    /**
     * @param {Object<{
     *     error: string,
     *     message: string
     * }>} props
     */
    constructor(props) {
        const {
            error,
            message: error_message,
            ...rest
        } = props;

        process.env.REACT_APP_ENV === 'local' && console.log(props);
        store.dispatch(showAlert({
            type: 'error',
            title: 'Error',
            text: typeof error_message === 'object' && error_message.length >= 0 ? error_message.join('<br/>') : error_message,
            confirmButtonText: 'Continue',
            onConfirm: () => {

            }
        }));
    }

}

export default UserError;
